import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {SettingsService} from '../../services/settings.service';
import {CalendarComponent} from '../calendar/calendar.component';
import * as q from 'q';

@Component({
  selector: 'article-search-text',
  templateUrl: './article-search-text.component.html',
  styleUrl: './article-search-text.component.css',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ArticleSearchTextComponent,
    multi: true
  }]
})
export class ArticleSearchTextComponent implements OnInit, ControlValueAccessor  {

  public overlayId: string = Date.now() + '_' + Math.floor((Math.random() * 9999) + 1000);
  public OverlayActive = false;
  private currentSelectedOption: number = null;
  // Kann auch auf @Input() umgestellt werden und dann von Außen kommen. Oder per HTTP Rest Anfrage gefüllt werden
  public overlayOptions : any
  public hasNoFocus: boolean = true;
  @Input()  public tabindex: number;
  @Input()  public id: string;
  @Input() public realId: string;
  @Input()  public placeholder: string;
  @Input()  public ngModel: string;
  @Input() public article : string;
  @Output() public articleChange  = new EventEmitter<any>();
  @Input() public unit : string;
  @Output() public unitChange  = new EventEmitter<string>();
  @Input() public price : string;
  @Output() public priceChange  = new EventEmitter<string>();
  @Input() public taxRate : string;
  @Output() public taxRateChange  = new EventEmitter<string>();

  @Output() public ngModelChange = new EventEmitter<string>();
  @ViewChild( 'overlayContainer') overlayContainer: any;
  modelChanged() { this.ngModelChange.emit(this.ngModel); }
  @Output() public focus  = new EventEmitter<FocusEvent>();
  @Output() public blur  = new EventEmitter<FocusEvent>();
  @Output() public change  = new EventEmitter<any>();
  @Output() public click  = new EventEmitter<MouseEvent>();

  @Input() disabled: boolean | string; // true oder null
  @Input() highlight: boolean; // Aktiviert die orange Markierung für aktiv bei der Suche oder nicht ausgefüllt bei einem Formular

  public overlayWidth: number = 400;

  constructor(public http: HttpClient,
              public settings: SettingsService) {
  }

  onFocusEvent(event: any){
    console.log(event.target.value);
  }

  ngOnInit(): void {
    if(this.placeholder == undefined) { this.placeholder = ''; }
  }
  focusEvent($event: FocusEvent) { this.hasNoFocus = false; this.focus.emit($event); }
  blurEvent($event: FocusEvent) {
    console.log('blur');

    if(this.overlayOptions!= undefined && this.overlayOptions[this.currentSelectedOption]!=undefined) {
      this.selectOverlayValue(this.overlayOptions[this.currentSelectedOption]);
    }

    setTimeout(() => {
          console.log('blurEvent');
          this.deactivateOverlay(); this.hasNoFocus = true;
          if( this.blur != undefined ) { this.blur.emit($event); }
        }, 200);
     }
  changeEvent($event: Event) { this.change.emit($event); }
  clickEvent($event: MouseEvent) {
    $event.stopPropagation(); // Verhindert das doppelte Auslösen des Events.
    this.click.emit($event);
  }
  keyupEvent(event: KeyboardEvent) {
    console.log(event.key);
    if(event.ctrlKey == false && event.key=='Control') { return; }
    if (event.key == 'ArrowDown') {
      if(this.OverlayActive) {
        if(this.currentSelectedOption==null) { this.currentSelectedOption = 0; if(this.overlayOptions.length) { this.overlayOptions[this.currentSelectedOption].selected = true;} } 
        else if( (this.currentSelectedOption+1) < this.overlayOptions.length) {
          this.overlayOptions[this.currentSelectedOption].selected = false;
          this.currentSelectedOption++;
          this.overlayOptions[this.currentSelectedOption].selected = true;
        }
      } else {
        this.activateOverlay();
        this.currentSelectedOption = null;
        this.overlayOptions[this.currentSelectedOption].selected = true;
      }
    } else if (event.key == 'ArrowUp') {
      if(this.OverlayActive) {
        if(this.currentSelectedOption>0) {
          this.overlayOptions[this.currentSelectedOption--].selected = false;
          this.overlayOptions[this.currentSelectedOption].selected = true;
        }
      }
    } else if (event.key == 'Enter') {
      if(event.ctrlKey) {
        this.strgReturnEvent.emit();
        this.deactivateOverlay();
       // setTimeout(function(){   document.getElementById(this.overlayId+'ArticleSearchText').blur(); return;}, 1000);
        //
        return;
      } else {
        this.selectOverlayValue(this.overlayOptions[this.currentSelectedOption]);
        this.deactivateOverlay();
      }

    } else if (event.key == 'Escape') {
      this.deactivateOverlay();
    } else {
      if(this.ngModel != undefined && this.ngModel.length > 2) { this.search(); }
      this.modelChanged();
    }
  }

  public search() {
    this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'erp/article', { text: this.ngModel, active: 1, newdata:1}, this.settings.httpOptions )
        .subscribe({
              next: (data: { status, count, data }) => {
                this.overlayOptions = data.data;
                this.currentSelectedOption = null;
                this.activateOverlay();
                return;
                if(this.overlayOptions != undefined && this.overlayOptions.length > 0) {

                  this.overlayOptions[this.currentSelectedOption].selected=true;
                }
              },
              error: (error) => {
                console.log(error);
              }
            }
        );
  }
  selectOverlayValue(option: { name:string, id:string, selected:boolean, price: string, fk_erp_unit:string , fk_erp_tax_rate:string}) {
    this.ngModel = option.name;
    console.log('Artikel selekt');
    console.log(option);
    this.articleChange.emit(option);
    this.priceChange.emit(option.price);
    this.unitChange.emit(option.fk_erp_unit);
    this.taxRateChange.emit(option.fk_erp_tax_rate);
    this.change.emit(option);
    this.modelChanged();
    this.deactivateOverlay();
    this.currentSelectedOption = null;
  }
  
  deactivateOverlay() { this.OverlayActive = false; this.currentSelectedOption=null; }

  activateOverlay() {
    // Position der Box über oder unter Eingabefeld je nach Position auf dem Schirm
    this.OverlayActive = true;
  }

  public onFocus(): void {
    setTimeout(function(){   document.getElementById(this.overlayId+'ArticleSearchText').focus(); return;}, 1000);
  }

  // Folgende Methoden werden für Angular reactive Forms benötigt. Sonst wird eine Fehlermeldung ausgegeben.
  // Nicht implementiert, da wir diese nicht benutzen.
  @Output() strgReturnEvent = new EventEmitter<unknown>();

  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

}
