import {
  Component,
  Input,
  Output,
  OnInit,
  OnChanges,
  EventEmitter,
  SimpleChanges,
  ViewChild,
  HostListener
} from '@angular/core';
import {SettingsService} from '../../services/settings.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { trigger, style, transition, animate } from '@angular/animations';
import { ModalService } from 'src/app/_modal';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-manager',
  templateUrl: './contact-manager.component.html',
  styleUrls: ['./contact-manager.component.css'],
  animations: [
    trigger('fadeIn', [
      transition (':enter', [
        style({ opacity: 0}),
        animate('200ms ease-in', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class ContactManagerComponent implements OnInit, OnChanges  {

  // Die untere Struktur aktiviert das 2 Wege Databinding
  // im Element muss die externe Variable so zugewiesen werden: [(model)]="variable"
  // um die Variablen Änderung nach Außen zu kommunizieren muss wie in der unten stehenden
  // Methode changeModel() die Methode this.modelChange.emit('VALUE');


  // display variables
  public overlayId: string;
  public OverlayActive: boolean;
  public showLeft1Column = false;
  public showNewItemDropdown = false;
  public showNewItemCompanyForm = false;
  public showNewItemSiteForm = false;
  public showNewItemContactForm = false;
  public showNewItemContactFormFromExistingContact = false;
  public showEditContactForm = false;
  public suggestSearchActive = false;
  public contactManagerSearch = {text : '', active : '1', tag:0};
  public currentSelectedContact;
  public mergeContactId;
  public currentSelectedContactData;
  public contactFormTypesData = [];
  public companyAddressTypesData = [];
  public bookAccountsList = [];
  public bookAccountArray = [];
  public selectedInfoType = { id: '-1', value: '', public_team: '0' };
  public showContactInfoInputToAdd = false;
  public showContactAddressInputToAdd = false;
  public showCompanyAddContact = false;
  public showCompanyNewSiteForm = false;
  public showEditContactData = false;
  public showEditContactInformation = false;
  public currentlyEditingContactInfo;
  public showAddressEdit = false;
  public currentlyEditingContactAddress;
  public showAssignContact = false;
  public showMergeContact = false;
  public siteIdToAssignContact;
  public showSitestoAssignContact = false;
  public showExistingContactDropdown = false;
  public existingContact = [];
  public siteLink;
  public assignableSites;
  public currentSelectedCompanyId;
  public currentSelectedCompanyIdBackup;
  public currentSelectedCompanyData;
  public currentSelectedSite;
  public currentSelectedSiteData;
  public currentSelectedCompanyTab;
  public httpOptions;
  public displayDivContent;
  public searchResultCompany ;
  public searchResultContact ;
  public currentSite: void;
  public suggestSearchString = '';
  public suggestSearchResult;
  public suggestViewAdditionalInformation;
  public suggestKeyCurrentSelectedIndex;
  public suggestKeyCurrentSelected;
  public starCount = new Array(5);
  public favouriteToDelete;

  @Input() settabindex: any;
  @Input() tabindex: any;
  @Input() onlyIcon;
  @Input() mode = null;
  @Input() markOnSet: boolean;
  @Input() mark: boolean;

  @Input() company: any;
  @Input() site: any;
  @Input() contact: any;
  @Input() connectId: any;
  @Output() companyChange = new EventEmitter<string>();

  @Input() groupId: any;
  @Output() siteChange = new EventEmitter<string>();
  @Output() contactChange = new EventEmitter<string>();
  @Output() connectIdChange = new EventEmitter<string>();
  @Output() groupIdChange = new EventEmitter<string>();
  @Output() nameStrings = new EventEmitter< { company,  company_short, site, site_short, contact_salutation, contact_title, contact_first_name, contact_name, email, phone} >();
  @Output() quickSaveStrings = new EventEmitter< { company_name, site_name } >();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() addContact: EventEmitter<any> = new EventEmitter();

  public nameStringsIntern: any;
  public SuggestOverlayActive: any;
  public toolTipContent: any;
  public displayTooltipContent: string;
  public viewMode = 1;
  public listFilter;
  public currentSelectedListItem: any;
  public listData: any;
  public groupData = null;
  public salutations = [];
  public titles = [];
  public pwTypes = [];
  public contactConnectionArray = [];
  public contactContentData;
  public companyContentData;
  public addContactAddressData = {street: '', street_number: '', postcode: '', city: '', public_team: '0'};
  public addSiteData = {site_name: '', site_short_name: '', street: '', street_number: '', postcode: '', city: ''};
  public editCompany = false;
  public editCompanyDomains = 0;
  public editSite = false;
  public contactTeams: any;
  public selectedCompanyUser =  -1;
  public showDeleteSiteAction = false;
  public deleteSiteAction = '';
  public siteIdToReassign = '';
  public showMainAddressSelect = false;
  public newSelectedMainAddress = '';
  public selectableNewMainAddresses = [];
  public mainAddressId: any;
  public quickSaveData ;
  public showQuickSave = false;
  public showQuickSaveCompanySelected = false;
  public showCompanyData = false;
  public showContactData = true;
  public suggestedCompany;
  public quicksaveSiteData;
  public companyOverlayId;
  public domainOverlayId;
  public suggestedDomain;
  public showSuggestedDomain = false;
  public showSuggestedName = false;
  public suggestedName;
  public nameOverlayId;
  public showCompanyOverlay = false;
  public showEmptyFavoriteFieldCompany = false;
  public showEmptyFavoriteFieldContact = false;
  public favoritesLoaded = false;
  public showCompanyFavoriteContacts = false;
  public selectedPhoneId;
  public selectedMailId;
  public contactManagerMessageContent;
  public groupFilter: any;
  public currentSelectedContactGroup = null;
  public newGroupContactItem: any;
  public newGroupContactNameString: any;
  public groupItemToDelete = null;
  public groupContactManagerReset = true;
  public paymentMethods: any;
  public currentListEditItem = null;
  public showLoading = false;
  public userActionMenu = {show:false,x:200,y:200};
  public suggestSearchTimer = null;
  public contactTag: any;
  public saveSwitchNumber = 0;
  public idSaver = 0;
  public editTab = 0;
  public saveSwitchMode = 1;
  public modeValue;
  public assignCompanyWithoutList = 0;
  public showAssignToCompany = false;
  public companyFromContact = false;
  public usermanagmentRight = false;
  public internMode = '';
  public suggestMode = 1;
  public changeToSiteAfterLoad = false;
  private suggestSelectedSoDontBuildDivLater = false;
  private justClickedTimestamp: number = 0;

  public initNewGroupContactItem() {
    this.newGroupContactItem = null;
    this.newGroupContactItem = { contact: 0, company: 0, site: 0, connectId: 0, groupId: 0};
  }
  public initListFilter() {
    this.listFilter = { sort: 'name', active: '1', type: '3', public: '0', text: '', tag: 0, mode: 'contact'};
  }
  public initGroupFilter() {
    this.groupFilter = { sort: 'name', active: '1', type: '3', public: '0' , text: ''};
  }
  public initCurrentSelectedContactData() {
    this.currentSelectedContactData = {id: '', company: '', site: '', groupId: '' };
  }
  public initCurrentSelectedGroupData() {
    this.currentSelectedContactGroup = {id: 0, name: '', description: '', contact: [] };
  }
  public initCurrentSelectedCompanyData() {
    this.currentSelectedCompanyData = { id: 0, name: '', nickname: '', supporter: '', tel: '', address: [], create_user: '', customer_fk_erp_payment_methods: '', bill_receiver: '' };
  }

  public initContactContentData() {
    this.contactContentData = { salutation: '-1', title: '-1', first_name: '', name: '', email: '', commentary: '', phoneBusiness: '', faxBusiness: '', phonepublic: '', phoneMobile: '', street: '', street_number: '', postcode: '', city: '', position: '', profession: '', pwType: '-1', pwName: '', pwUser: '', pwPassword: '', pwPublic: '', pwComment: '' };
  }

  public initCompanyContentData() {
    this.companyContentData = { name: '', nickname: '', customer_id: '', notice: '', street: '', street_number: '', postcode: '', city: '', receivalbe_accounts: '', payable_accounts: '', bookAccount1: '-1', bookAccount2: '-1', bookAccount3: '-1', bookAccount4: '-1', tel: '', tel_main: '', mail_domains: '' };
    this.currentSelectedCompanyId = 0;
  }

  public initNameStringsIntern() {
    this.nameStringsIntern = { company: '',  company_short: '', site: '', site_short: '', contact_salutation: '', contact_title: '', contact_first_name: '', contact_name: '' , phone: '', email: '', groupId: ''  };
  }

  public initQuicksaveData() {
    this.showQuickSaveCompanySelected = false;
    this.quickSaveData = { type: 'public', salutation: '-1', title: '-1', name: '', first_name: '', phone: '', email: '', company_name: '', company_nickname: '', site_name: '', site_short_name: '', street: '', street_number: '', postcode: '', city: '', companyId: 0 , siteId: 0 };
  }

  @HostListener('document:keydown ', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.deactivateOverlay();
    }
  }

  constructor(public router: Router, public http: HttpClient, public settings: SettingsService, public message: ModalService) {
    if ( this.markOnSet === undefined ) { this.markOnSet = false; }
    if ( this.mark === undefined ) { this.mark = false; }
    if ( this.settabindex > 0 ) { this.tabindex = this.settabindex; }
    this.OverlayActive = false;
    this.initNameStringsIntern();
    this.initQuicksaveData();
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
    this.currentSelectedCompanyTab = 0;
    this.initCurrentSelectedContactData();
    this.initCurrentSelectedCompanyData();
    this.initContactContentData();
    this.initListFilter();
    this.initGroupFilter();
    this.initNewGroupContactItem();

    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Benutzerverwaltung/Benutzerliste', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {  if (data.message === '1') { this.usermanagmentRight = true; } }
        ));

    this.http.post<any[]>(settings.restBaseUrl + 'contact/salutation' , {}, settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          data.forEach((item) => {
            if (item.type === '0') {
              this.salutations.push({ id: item.id, name: item.name });
            } else {
              this.titles.push({ id: item.id, name: item.name });
            }
          });
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'contact/types' , { type: 2 }, settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.contactFormTypesData = data;
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'contact/types' , { type: 3 }, settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.companyAddressTypesData = data;
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'contact/pwTypes' , {}, settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
              this.pwTypes = data;
        }
    );

    this.http.post<any[]>(settings.restBaseUrl + 'erp/account/0' , {}, settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.bookAccountsList = data;
        }
    );

    this.http.post<{ status, message, data, count }>(settings.restBaseUrl + 'erp/paymentmethod' , {}, settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.paymentMethods = data.data;
        }
    );

    this.http.post<{ data }>(this.settings.restBaseUrl + 'contact/tag' , {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.contactTag = data.data;
        }
    );
  }

  ngOnInit() {
    if (this.mode !== 'free' && this.mode !== 'company' && this.mode !== 'site' && this.mode !== 'email' && this.mode !== 'phone' && this.mode !== 'group' && this.mode !== 'dms_search' && this.mode !== 'dms_assign')  { this.mode = 'contact'; }
    this.suggestSearchActive = false;
    this.modeValue = this.mode;
    const dateNow =  Date.now();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
    this.companyOverlayId = + dateNow + '-' + Math.floor((Math.random() * 9999) + 1001);
    this.domainOverlayId = + dateNow + '-' + Math.floor((Math.random() * 9999) + 1002);
    this.nameOverlayId = + dateNow + '-' + Math.floor((Math.random() * 9999) + 1003);
  }

  ngOnChanges(changes: SimpleChanges) {
    let changeToCompany = 0;
    let changeToSite = 0;
    let changeToContact = 0;
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if ( changes[propName].currentValue == changes[propName].previousValue) { return; } // nur wenn der change von außen kommt.
        if ( changes[propName].currentValue != undefined || Number(changes[propName].currentValue) >= 0) {
          switch (propName) {
            case 'contact':
              changeToContact = changes[propName].currentValue;
              break;
            case 'site':
              changeToSite = changes[propName].currentValue;
              break;
            case 'company':
              changeToCompany = changes[propName].currentValue;
              break;
          }
        }
      }
    }
    if(changeToContact > 0) {
      // Nur Kontakt
      this.view({id: changeToContact} );
      return;
    } else if(changeToCompany > 0) {
      // Nur Firma
      this.viewCompany(changeToCompany);
      return;
    } else
     {
      setTimeout(() => {
        this.contact = 0;
        this.site = 0;
        this.displayDivContent = '';
        this.displayTooltipContent = '';
        this.siteChange.emit('0');
        this.contactChange.emit('0');
        this.displayDivContent = '';
      }, 200);
    }

    this.suggestSearchActive = false; // Schnellsuche zu wenn Update von Außen
  }

  applySelectedValues() {
    this.initNameStringsIntern();
    this.showCompanyFavoriteContacts = false;
    if (this.mode === 'email') {
      // Prüfen, ob eine E-Mail gewählt wurde.
      if (this.currentSelectedContactData.email=='' || this.currentSelectedContactData.email==null || this.currentSelectedContactData.email==undefined) {
        this.contactManagerMessageContent = 'Sie müssen eine E-Mail Adresse auswählen, um diese zu Übernehmen!';
        this.message.open('contactManagerMessageBox' + this.overlayId);
        return;
      }
      this.site = 0;
      this.company = this.currentSelectedCompanyId;
      this.contact = this.currentSelectedContactData.id;
      const parentThis = this;
      if (this.contact > 0 ) {
        this.displayTooltipContent = '<span>' ;
        if ( this.currentSelectedContactData.salutationString !== null ) {
          this.displayDivContent =  this.currentSelectedContactData.salutationString + ' ';
          this.displayTooltipContent += this.currentSelectedContactData.salutationString + ' ';
        }
        if ( this.currentSelectedContactData.first_name !== undefined ) {
          this.displayDivContent +=  this.currentSelectedContactData.first_name;
          this.displayTooltipContent += this.currentSelectedContactData.first_name + ' ';
        }
        if ( this.currentSelectedContactData.name !== undefined) {
          this.displayDivContent +=  ' <b>' + this.currentSelectedContactData.name + '</b> ';
          this.displayTooltipContent += '<b style=\"margin-bottom:4px;\">' + this.currentSelectedContactData.name + '</b>';
        }
        this.displayTooltipContent += '</span>';
        if (this.company > 0 ) {
          this.displayDivContent += '(' + this.currentSelectedCompanyData.name + ') ';
          this.displayTooltipContent += '<B>' + this.currentSelectedCompanyData.name + '</B><br>';
        } else {
          this.displayDivContent += '<B>Privatperson </B> ';
          this.displayTooltipContent += '( Privatperson ) <br>';
        }
        if ( this.currentSelectedContactData.email ) {
          this.displayDivContent += this.currentSelectedContactData.email;
          this.displayTooltipContent += this.currentSelectedContactData.email;
        }
        this.displayTooltipContent += ' ';
        setTimeout(() => { parentThis.onChange.emit(); }, 100);
      }

    } else if (this.mode === 'site') {
      if (this.currentSelectedCompanyTab > 0) {
        this.site = this.currentSelectedCompanyTab;
        this.company = this.currentSelectedCompanyId;
        this.contact = 0;
        const parentThis = this;
        setTimeout(() => { parentThis.onChange.emit(); }, 100);

        this.displayDivContent = this.currentSelectedCompanyData.nickname + ' - <b>' + this.currentSelectedSiteData.site_short_name + '</b>';
        this.displayTooltipContent = '<span><b style=\"margin-bottom:4px;\">' + this.currentSelectedSiteData.site_name + '</b> </span>' + this.currentSelectedCompanyData.name + '<br>';

      } else { alert('Sie müssen das gewünschte Standort Tab auswählen um diesen zu Übernehmen!'); return; }
    } else if (this.mode === 'company') {
      if (this.currentSelectedCompanyId > 0) {
        this.company = this.currentSelectedCompanyId;
        this.site = 0;
        this.contact = 0;
        const parentThis = this;
        setTimeout(() => { parentThis.onChange.emit(); }, 100);
        this.displayDivContent = '<b>' + this.currentSelectedCompanyData.name + '</b> \n';
        this.displayTooltipContent = '<span><b>' + this.currentSelectedCompanyData.name + '</b></span>';
      } else { alert('Sie müssen erst eine Firma auswählen um diese zu Übernehmen!'); return; }
    }  else if (this.mode === 'free') {
        this.company = this.currentSelectedCompanyId;
        this.contact = this.currentSelectedContactData.id;
        this.site = this.currentSelectedCompanyTab;
        const parentThis = this;
        setTimeout(() => { parentThis.onChange.emit(); }, 100);
        // Je nachdem was gewählt dies anzeigen
        if ( this.contact < 1 && this.site < 1) {
          // company
          this.displayDivContent = '<b>' + this.currentSelectedCompanyData.name + '</b> \n';
          this.displayTooltipContent = '<span><b>' + this.currentSelectedCompanyData.name + '</b></span>';
          this.buildDisplayDivContent();
        } else if ( this.contact < 1 ) {
          // site
          this.displayDivContent = this.currentSelectedCompanyData.nickname + ' - <b>' + this.currentSelectedSiteData.site_short_name + '</b>';
          this.displayTooltipContent = '<span><b style=\"margin-bottom:4px;\">' + this.currentSelectedSiteData.site_name + '</b> </span>' + this.currentSelectedCompanyData.name + '<br>';
          this.displayTooltipContent += '<i>(Standort)</i>';
          this.buildDisplayDivContent();
        } else {
          // contact
          this.buildDisplayDivContent();

        }
    } else {
      if (this.currentSelectedContact && this.currentSelectedContact.id > 0) {

        if (this.mode === 'phone' && this.currentSelectedContact.phone === null) {
          alert('Sie müssen einen Kontakt mit einer Telefonnummer auswählen!');
          return;
        }
        if (this.mode === 'email' && this.currentSelectedContact.email === null) {
          alert('Sie müssen einen Kontakt mit einer E-Mail Adresse auswählen!');
          return;
        }

        this.contact = this.currentSelectedContactData.id;
        this.site = this.currentSelectedContactData.site;
        this.company = this.currentSelectedCompanyId;

        this.buildDisplayDivContent();

        if (this.currentSelectedContactData.phone != null) {
          this.displayTooltipContent += this.currentSelectedContactData.phone + ' \n';
          if (this.mode === 'phone') {
            this.displayDivContent += ' ' + this.currentSelectedContactData.phone;
          }
        }

        if (this.currentSelectedContactData.email != null) {
          this.displayTooltipContent += this.currentSelectedContactData.email + ' ';
          if (this.mode === 'email') {
            this.displayDivContent += ' ' + this.currentSelectedContactData.email;
          }
        }

      } else {
        if (this.mode === 'site' && this.currentSelectedCompanyTab < 1 || this.mode === 'company' && this.currentSelectedCompanyId < 1 ||
        this.mode === 'contact' && this.currentSelectedContactData.id < 1 || this.mode === 'email' && this.currentSelectedContactData.e_mail == null ||
        this.mode === 'phone' && this.currentSelectedContactData.phone == null || this.mode === 'free' && this.currentSelectedCompanyTab < 1 &&
        this.currentSelectedCompanyId < 1 && this.currentSelectedContactData.id < 1) {
        alert('Sie müssen einen Kontakt auswählen um diesen zu Übernehmen!'); return;
        }
       }
    }

    if ( this.currentSelectedContactData.phone !== '' ) {
      this.nameStringsIntern.phone = this.currentSelectedContactData.phone;
    }

    if ( this.currentSelectedContactData.email !== '' ) {
      this.nameStringsIntern.email = this.currentSelectedContactData.email;
    }

    this.companyChange.emit(this.company);
    this.siteChange.emit(this.site);
    this.contactChange.emit(this.contact);
    this.nameStrings.emit(this.nameStringsIntern);

    this.deactivateOverlay();
  }

  buildDisplayDivContent() {
    if(this.suggestSelectedSoDontBuildDivLater == true) {
      this.suggestSelectedSoDontBuildDivLater == true;
      return;
    }
    this.displayDivContent = '';

    if(this.contact < 1 && this.site < 1 && this.company> 0) {
      // Nur Firma
      this.displayDivContent = this.currentSelectedCompanyData.nickname + ' (Firma)';
      return;
    }

    // Kontakt
    if (this.currentSelectedContactData.salutationString != null) {
      this.displayDivContent +=  this.currentSelectedContactData.salutationString + ' ';
      this.nameStringsIntern.contact_salutation = this.currentSelectedContactData.salutationString;
    }

    if (this.currentSelectedContactData.titleString != null) {
      this.displayDivContent += this.currentSelectedContactData.titleString + ' ';
      this.nameStringsIntern.contact_title = this.currentSelectedContactData.titleString;
    }

    if (this.currentSelectedContactData.first_name != null) {
      this.displayDivContent += this.currentSelectedContactData.first_name + ' ';
      this.nameStringsIntern.contact_first_name = this.currentSelectedContactData.first_name;
    }

    this.displayDivContent +=  '<b>' + this.currentSelectedContactData.name + '</b> \n';
    this.nameStringsIntern.contact_name = this.currentSelectedContactData.name;

    this.displayTooltipContent = '<span>' + this.displayDivContent + '</span>';

    if ( this.currentSelectedCompanyData && this.currentSelectedCompanyData.name !== '' ) {
      this.nameStringsIntern.company = this.currentSelectedCompanyData.name;
    }

    if ( this.currentSelectedContactData.site_name !== '' ) {
      this.nameStringsIntern.site = this.currentSelectedContactData.site_name;
    }

    if ( this.currentSelectedContactData.site_short_name !== '' ) {
      this.nameStringsIntern.site_short = this.currentSelectedContactData.site_short_name;
    }
    if (this.currentSelectedCompanyId > 0 && this.currentSelectedCompanyData.nickname !== '') {
      this.displayTooltipContent += '<b style=\"margin-bottom:4px;\">' + this.currentSelectedCompanyData.name + '</b> \n';
      this.displayDivContent +=  '( ' + this.currentSelectedCompanyData.nickname + ' )';
      this.nameStringsIntern.company_short = this.currentSelectedCompanyData.nickname;
    } else {
      this.displayDivContent +=  '( Privatperson )';
      this.displayTooltipContent += 'Privatperson\n';
      this.nameStringsIntern.company_short = '( Privatperson )';
    }
  }

  activateOverlay(): void {
    this.deactivateSuggestOverlay();

    this.http.post<{ data }>(this.settings.restBaseUrl + 'team' , { active: '1' , usersTeams: '1' }, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.contactTeams = data.data;
        }
    );

    this.OverlayActive = true;
    setTimeout( function focus() { document.getElementById('contactManagerSearch').focus(); }, 200);

    if ( this.company > 0 && this.currentSelectedCompanyId !== this.company ) {
      this.viewCompany(this.company);
    }

    if ( this.contact > 0 ) {
      this.view({id: this.contact});
    }

    if(this.contact < 1 && this.company < 1) {
      this.showFavorites();
    }
  }

  deactivateOverlay(): void {
    this.viewMode = 1;
    this.currentSelectedContactGroup = null;
    this.OverlayActive = false;
    this.hardResetContactManager();
    this.showCompanyFavoriteContacts = false;
    this.userActionMenu.show = false;
  }

  toggleOverlay() {
    if (this.OverlayActive === false) {
      this.activateOverlay();
      if( ( this.company==undefined || this.company<1) && ( this.contact==undefined || this.contact<1) ) {
        this.getFavoriteCompaniesList();
        this.getFavoriteContactsList();
      }
    } else { this.deactivateOverlay(); }
  }

  searchKeyUp(event){
    // Jede Eingabe löscht und setzt einen Timer neu für die Suche.
    if (event.key === 'Enter') {
      if (this.contactManagerSearch.text.length > 2) {
        this.search();
      } else {
        this.contactManagerMessageContent = 'Sie müssen mindestens ein 3 Buchstaben langes Suchwort!';
        this.message.open('contactManagerMessageBox' + this.overlayId);
      }
    }
  }

  tagSearchChanged() {
    if(this.contactManagerSearch.tag.toString() === '0' && this.contactManagerSearch.text.length < 3) {
      this.resetSearch();
      return;
    }
    this.search();
  }

  searchArchive()
  {
    this.contactManagerSearch.active = '0';
    this.message.close('searchArchive' + this.overlayId);
    this.search();
  }

  search() {
    this.editCompany = false;
    this.editSite = false;
    this.favoritesLoaded = false;
    this.showCompanyFavoriteContacts = false;
    const countValue = this.contactManagerSearch.text.trim();
    const valueArray = countValue.split(/\s+/);
    if (valueArray.length > 3) { return; }

    if(this.showAssignToCompany) {
      // Privatperson einer Firma zuweisen
      this.mergeIntoCompanySearch();
      return;
    }
    if (!this.showAssignContact && !this.showMergeContact) {
      this.showLeft1Column = false;
      this.showExistingContactDropdown = false;
      this.resetContactManager();
    }
    if (this.viewMode === 2) {
      // Wenn im Listen Modus die listenSuche starten.
      this.updateList();
      return;
    }
    if (this.viewMode === 3) {
      // Wenn im Listen Modus die listenSuche starten.
      this.groupFilter.text = this.contactManagerSearch.text;
      this.updateGroupList();
      return;
    }
    if  (this.contactManagerSearch.text.length > 2 || this.contactManagerSearch.tag > 0) {
      if (this.showMergeContact) {
        this.http.post<any[]>(this.settings.restBaseUrl + 'contact/full/contact' , { filter_id: this.currentSelectedContact.id ,text: this.contactManagerSearch.text, active: 1, company: this.currentSelectedContact.companyId, tag: this.contactManagerSearch.tag }, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
              this.assignableSites = data;
            }
        );
      } else if (this.showAssignContact) {

        this.http.post<any[]>(this.settings.restBaseUrl + 'contact/company/address/full' , { text: this.contactManagerSearch.text, active: '1' }, this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
              this.assignableSites = data;
            }
        );
      } else {
        this.http.post<any>(this.settings.restBaseUrl + 'contact/company', { text: this.contactManagerSearch.text,tag: this.contactManagerSearch.tag, mode: this.mode, active: '1'}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
              this.searchResultCompany = data;
              this.showEmptyFavoriteFieldCompany = false;
              this.searchResultCompany.forEach((element) => {
                if (element.favorite != null) { this.showEmptyFavoriteFieldCompany = true; }
              });
            }
        );
        if (this.mode === 'free' && this.OverlayActive == false) { this.internMode = 'full'; }else if(this.OverlayActive == true){this.internMode = 'contact'}else{this.internMode = this.mode}
        this.http.post<{data, status, count }>(this.settings.restBaseUrl + 'contact', {text: this.contactManagerSearch.text , tag: this.contactManagerSearch.tag , mode: this.internMode , active: this.contactManagerSearch.active}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
              // @ts-ignore
              this.searchResultContact = data.data;
              if(!this.searchResultContact.length && this.contactManagerSearch.active != '0'){
              this.message.open('searchArchive' + this.overlayId);}
              this.showEmptyFavoriteFieldContact = false;
              this.searchResultContact.forEach((element) => {
                if (element.favorite != null) { this.showEmptyFavoriteFieldContact = true; }
              });
            }
        );
      }
    } else {
      this.contactManagerMessageContent = 'Sie müssen mindestens ein 3 Buchstaben langes Suchwort oder einen Tag angeben um zu Suchen!';
      this.message.open('contactManagerMessageContent');
    }
  }

  add() {
    this.showNewItemDropdown = !this.showNewItemDropdown;
    this.showExistingContactDropdown = false;
  }

  selectNewItemType(select) {

    this.closeAssignUi();
    this.viewMode = 1;
    this.showNewItemContactForm = false;
    this.showNewItemSiteForm = false;
    this.showNewItemCompanyForm = false;
    this.showEditContactForm = false;
    this.showCompanyNewSiteForm = false;
    this.showCompanyAddContact = false;
    this.showLeft1Column = true;
    this.currentSelectedSiteData = null;
    this.currentSelectedSite = null;
    this.initCurrentSelectedCompanyData();
    this.currentSelectedCompanyId = 0;

    if (select === 'addNewCompany') {
      this.showNewItemCompanyForm = true;
      this.currentSelectedContactData = null;
      this.currentSelectedContact = null;
      this.initCompanyContentData();
      this.initContactContentData();
      this.focusOnId('newCompanyName');
    } else if (select === 'AddNewContact') {
      this.showNewItemContactForm = true;
      this.currentSelectedContactData = null;
      this.currentSelectedContact = null;
      this.initContactContentData();
      this.initCompanyContentData();
      this.focusOnId('newContactSalutation');
    } else if (select === 'companyFromContact')
    {
      this.initCompanyContentData();
      this.showNewItemCompanyForm = true;
      this.focusOnId('newCompanyName');
    }
    this.showNewItemDropdown = false;
  }

  newItemBack() {
    this.showLeft1Column = false;
    this.companyFromContact = false;
    this.showNewItemCompanyForm = false;
    this.showExistingContactDropdown = false;
  }

  addInfoToCurrentCompany(item) {
    this.viewMode = 1;
    let company = '';

    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/company', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              company = data.message;
              this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Kontaktmanager/Kontaktverwaltung', this.settings.httpOptions)
                  .pipe(map(data => data)).subscribe(
                  (data => {
                      if(company === this.currentSelectedCompanyId && data.message === '0'){this.message.open('rightCheckOwnCompany'  + this.overlayId)}
                      else{
                      this.closeAssignUi();
                      this.currentSelectedContactData = null;
                      this.currentSelectedContact = null;
                      this.showCompanyAddContact = false;
                      this.showCompanyNewSiteForm = false;
                      this.showNewItemDropdown = false;
                      if (this.currentSelectedCompanyId == null || this.currentSelectedCompanyId == 0 ) {
                        this.contactManagerMessageContent = 'Sie müssen eine Firma auswählen um dieser Informationen hinzufügen zu können.';
                        this.message.open('contactManagerMessageBox' + this.overlayId);
                        return;
                      } else {
                        if (item === 'addContactToCompany') {
                          this.initContactContentData();
                          this.showCompanyAddContact = true;
                          this.showNewItemContactForm = false;
                          if (this.currentSelectedSiteData && this.currentSelectedSiteData.id > 0 ) {
                            this.siteIdToAssignContact = this.currentSelectedSiteData.id;
                          } else {
                            this.siteIdToAssignContact = this.currentSelectedCompanyData.address[0].id;
                          }
                          this.focusOnId('addCompanyContactSalutation');
                        } else if ( item === 'addSiteToCompany') {
                          this.showCompanyNewSiteForm = true;
                          this.showNewItemCompanyForm = false;
                          this.focusOnId('newSiteName');
                        }
                      }}

                      }
                  ));

            }
        ));

  }

  public focusOnId(id) {
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 200);
  }

  companyAddFormBack() {
    this.showCompanyAddContact = false;
    this.showCompanyNewSiteForm = false;
  }

  addInfoToCurrentContact(item) {
    this.viewMode = 1;
    this.closeUpdateInterfaces();
    this.closeAssignUi();
    this.showContactAddressInputToAdd = false;
    this.showContactInfoInputToAdd = false;

    let typeString;
    // tslint:disable-next-line:triple-equals
    if (this.currentSelectedContact == undefined) {
      if (item === 'connectionPhonenumber') {
        typeString = 'Telefonnummer';
      } else if (item === 'connectionFaxnumber') {
        typeString = 'Faxnummer';
      } else if (item === 'connectionEmail') {
        typeString = 'E-Mail Adresse';
      } else if (item === 'connectionMobilenumber') {
        typeString = 'Handynummer';
      } else if (item === 'connectionAddress') {
        typeString = 'Adresse';
      } else if (item === 'connectionOthers') {
        typeString = 'weitere Information';
      }
      this.contactManagerMessageContent = 'Sie müssen einen Kontakt auswählen um diesem eine ' + typeString + ' hinzufügen zu können.';
      this.message.open('contactManagerMessageBox' + this.overlayId);

      this.showNewItemDropdown = false;
      return;
    } else {
      if (item === 'connectionPhonenumber') {
        this.selectedInfoType.id = '6';
        this.showContactInfoInputToAdd = true;
        this.showNewItemDropdown = false;
        this.focusOnId('newContactInfoValue');
      } else if (item === 'connectionFaxnumber') {
        this.selectedInfoType.id = '7';
        this.showContactInfoInputToAdd = true;
        this.showNewItemDropdown = false;
        this.focusOnId('newContactInfoValue');
      } else if (item === 'connectionEmail') {
        this.selectedInfoType.id = '8';
        this.showContactInfoInputToAdd = true;
        this.showNewItemDropdown = false;
        this.focusOnId('newContactInfoValue');
      } else if (item === 'connectionMobilenumber') {
        this.selectedInfoType.id = '18';
        this.showContactInfoInputToAdd = true;
        this.showNewItemDropdown = false;
        this.focusOnId('newContactInfoValue');
      } else if (item === 'connectionAddress') {
        this.selectedInfoType.id = '15';
        this.showContactAddressInputToAdd = true;
        this.showNewItemDropdown = false;
        this.focusOnId('contactNewAddressStreet');
      } else if (item === 'connectionOthers') {
        this.selectedInfoType.id = '-1';
        this.showContactInfoInputToAdd = true;
        this.showNewItemDropdown = false;
        this.focusOnId('newContactInfoType');
      }
    }
  }

  checkType() {
    if (this.selectedInfoType.id === '15') {
      this.showContactAddressInputToAdd = true;
      this.showContactInfoInputToAdd = false;
    } else {
      this.showContactAddressInputToAdd = false;
      this.showContactInfoInputToAdd = true;
    }
  }

  checkSaveNewCompany()
  {
    this.saveSwitchNumber = 1;
    if (this.existingContact.length > 0) {
      this.contactManagerMessageContent = 'Ein Kontakt mit dieser E-Mail-Adresse wurde bereits angelegt.<br>Weisen Sie den Vorhandenen Kontakt neu zu wenn es sich um die gewünschte <br>Person handelt oder überprüfen Sie die eingegebenen Daten erneut.';
      this.message.open('contactManagerQuestionBox' + this.overlayId);
      return;
    }else{this.saveNewCompany();}
  }

  saveNewCompany() {
    this.bookAccountArray = [];

    if (this.companyContentData.name === '' || this.companyContentData.name == null) {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Firmennamen ein.');
      return;
    } else if (this.companyContentData.nickname === '' || this.companyContentData.nickname == null) {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Kurznamen ein.');
      return;
    }

    if (this.companyContentData.bookAccount1 !== '-1') {
      this.bookAccountArray.push(this.companyContentData.bookAccount1);
    }
    if (this.companyContentData.bookAccount2 !== '-1') {
      this.bookAccountArray.push(this.companyContentData.bookAccount2);
    }
    if (this.companyContentData.bookAccount3 !== '-1') {
      this.bookAccountArray.push(this.companyContentData.bookAccount3);
    }
    if (this.companyContentData.bookAccount4 !== '-1') {
      this.bookAccountArray.push(this.companyContentData.bookAccount4);
    }

    this.showNewItemCompanyForm = false;

    const companySaveData = {
      name: this.companyContentData.name,
      nickname: this.companyContentData.nickname,
      tel: this.companyContentData.tel,
      tel_main: this.companyContentData.tel,
      mail_domains: this.companyContentData.mail_domains,
      receivable_accounts: this.companyContentData.receivable_accounts,
      payable_accounts: this.companyContentData.payable_accounts,
      customer_id: this.companyContentData.customer_id,
      notice: this.companyContentData.notice,
      intern: 0,
      active: 1
    };

    this.http.put<{status, message}>(
        this.settings.restBaseUrl + 'contact/company', companySaveData, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          const newID = data.message;

          if(this.companyFromContact && this.currentSelectedContactData && this.currentSelectedContactData.id) {
            // Wenn Kontakt in Firma wandel Aktion, dann nach erstellen der Firma den Kontakt an diese binden.
            this.currentSelectedCompanyId = newID;
            this.assignCompanyWithoutList = 1;
            this.assignContactToCompany();
          }

          const siteSaveData = {
            type: 10,
            site_name: this.companyContentData.name,
            site_short_name: this.companyContentData.nickname,
            street: this.companyContentData.street,
            street_number: this.companyContentData.street_number,
            postcode: this.companyContentData.postcode,
            city: this.companyContentData.city,
            fk_company_id: newID,
            fk_contact_id: 0
          };
          this.http.put<{status, message}>(
              this.settings.restBaseUrl + 'contact/company/sites', siteSaveData, this.settings.httpOptions)
              .pipe(map(data2 => data2)).subscribe(
              data2 => {
                const newSiteID = data2.message;
                const companyUpdateData = {
                  name: this.companyContentData.name,
                  nickname: this.companyContentData.nickname,
                  receivable_accounts: this.companyContentData.receivable_accounts,
                  payable_accounts: this.companyContentData.payable_accounts,
                  customer_id: this.companyContentData.customer_id,
                  notice: this.companyContentData.notice,
                  tel: this.companyContentData.tel,
                  tel_main: this.companyContentData.tel,
                  mail_domains: this.companyContentData.mail_domains,
                  intern: 0,
                  active: 1,
                  main_fk_addresses: newSiteID
                };
                this.http.put<{status, message}>(this.settings.restBaseUrl + 'contact/company/' + newID, companyUpdateData, this.settings.httpOptions)
                    .pipe(map(data3 => data3)).subscribe(
                    data3 => {});
                if (this.bookAccountArray.length > 0) {
                  this.addBookAccounts(newID);
                } else if (this.contactContentData.name != null && this.contactContentData.name !== '' && this.contactContentData.salutation > 0) {
                  this.addContactToCompany(newID);
                } else {
                  this.showLeft1Column = false;
                  this.showNewItemCompanyForm = false;
                  this.initCompanyContentData();
                  this.viewCompany(newID);
                }
              }
          );
        }
    );
  }

  checkSaveNewPublicContact() {
    this.saveSwitchNumber = 2;
    if (this.existingContact.length > 0) {
      this.contactManagerMessageContent = 'Ein Kontakt mit dieser E-Mail-Adresse wurde bereits angelegt.<br>Weisen Sie den Vorhandenen Kontakt neu zu wenn es sich um die gewünschte <br>Person handelt oder überprüfen Sie die eingegebenen Daten erneut.';
      this.message.open('contactManagerQuestionBox' + this.overlayId);
      return;
    } else {this.saveNewpublicContact();}
  }

  saveSwitch()
  {
    if(this.saveSwitchNumber === 1){this.saveNewCompany();}
    if(this.saveSwitchNumber === 2){this.saveNewpublicContact();}
    if(this.saveSwitchNumber === 3){this.addContactToCompany(this.idSaver);}
  }

  saveNewpublicContact() {

    if (this.contactContentData.name === '' || this.contactContentData.name == null) {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Namen ein.');
      return;
    } else if (this.contactContentData.salutation === '' || this.contactContentData.salutation == null) {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie eine Anrede aus.');
      return;
    }

    const contactSaveData = {
      salutation: this.contactContentData.salutation,
      title: this.contactContentData.title,
      first_name: this.contactContentData.first_name,
      name: this.contactContentData.name,
      company: 0,
      position: this.contactContentData.position,
      profession: this.contactContentData.profession
    };

    this.http.put<{status, message}>(
        this.settings.restBaseUrl + 'contact', contactSaveData, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          const newID = data.message;
          this.setContactConnections(newID);

          setTimeout(() => {
            const item = {id: newID};
            this.view(item);
          }, 300);
        }
    );
  }

  changePrivateContactToCompany()
  {
    this.companyFromContact = true;
    this.selectNewItemType('companyFromContact');
  }


  showCompaniesToAssignList() {
    if(this.currentSelectedContactData.login == '1') {
      // Wenn login dann nicht möglich
      this.contactManagerMessageContent = "Der Kontakt hat ein Anmeldekonto und kann deshalb nicht einer Firma zugewiesen werden!";
      this.message.open('src="../design/icons/group_add.png"' + this.overlayId);
      return;
    }
    this.assignableSites = [];
    this.contactManagerSearch.text = '';
    this.contactManagerSearch.active = '1';
    this.closeUpdateInterfaces();
    this.currentSelectedCompanyIdBackup = this.currentSelectedCompanyId;
    this.currentSelectedCompanyId = null;
    this.siteIdToAssignContact = null;
    this.showNewItemContactForm = false;
    this.showNewItemCompanyForm = false;
    this.showLeft1Column = true;
    this.showAssignToCompany = true;
    setTimeout( function focus() { document.getElementById('contactManagerSearch').focus(); }, 200);
  }

  mergeIntoCompanySearch() {
    this.http.post<any[]>(this.settings.restBaseUrl + 'contact/company/address/full' , { text: this.contactManagerSearch.text, active: 1, tag: this.contactManagerSearch.tag }, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.assignableSites = data;
        }
    );
  }

  showContactsToAssignList() {
    if(this.currentSelectedContactData.login == '1') {
      // Wenn login dann nicht möglich
      this.contactManagerMessageContent = "Der Kontakt hat ein Anmeldekonto und kann deshalb nicht mit einem anderen Kontakt zusammengeführt werden!";
      this.message.open('contactManagerMessageBox' + this.overlayId);
      return;
    }
    this.assignableSites = [];
    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Kontaktmanager/Kontaktverwaltung', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
          if (data.message === '1') {
            this.showLeft1Column = true;
            this.showMergeContact = true;
            this.contactManagerSearch = { text: '', active: '1', tag:0};
            setTimeout( function focus() { document.getElementById('contactManagerSearch').focus(); }, 300);
            } else {
              this.message.open('rightCheckMerge' + this.overlayId);
            }
          }
        ));
  }


  selectContactToMerge(item) {
    this.mergeContactId = item.id;
  }

  mergeContactQuestion()
  {
    this.message.open('mergeContactQuestion' + this.overlayId);
  }

  mergeContact()
  {
    this.message.close('mergeContactQuestion' + this.overlayId);
    this.message.open('waiting'+ this.overlayId);
    this.assignableSites=[];

      const mergeData = {
        mainContact: this.mergeContactId,
        oldContact: this.currentSelectedContact.id
      };
      this.http.put<{status, message}>(this.settings.restBaseUrl + 'contact/merge', mergeData, this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
              data => {
                this.message.close('waiting'+ this.overlayId);
                if(data[0].status === 'success'){
                  this.mergeContactBack();
                  this.search();
                  this.initCurrentSelectedContactData();
                  this.mergeContactId = 0;
                  this.currentSelectedContact=null;
                  this.message.open('mergeSucces' + this.overlayId);
                }
              }
          );
  }
  assignContactToCompanyDialog() {
    this.message.open('assignContactQuestion' + this.overlayId);
  }

  assignContactToCompanyDialogFromCompany(id) {
    this.assignCompanyWithoutList = 42;
    this.siteIdToAssignContact = id;
    this.currentSelectedCompanyId = id;

    this.message.open('assignContactQuestion' + this.overlayId);
  }

  assignContactToCompany() {
    this.deactivateCompanyOverlay();
    this.message.close('assignContactQuestion' + this.overlayId);
    let count = 0;
    if(this.assignCompanyWithoutList == 42){count = 2;}
    if(this.assignCompanyWithoutList == 0){ this.assignableSites.forEach((element) => {
      if (element.company_id === this.currentSelectedCompanyId) { count++; }
    });} this.assignCompanyWithoutList == 0;
    this.showAssignToCompany = false;
    const contactData = {
      salutation: this.currentSelectedContactData.salutation,
      title: this.currentSelectedContactData.title,
      name: this.currentSelectedContactData.name,
      first_name: this.currentSelectedContactData.first_name,
      company: this.currentSelectedCompanyId,
      position: this.currentSelectedContactData.position,
      profession: this.currentSelectedContactData.profession,
      search_number: this.currentSelectedContactData.search_number
    };
    this.http.put<{status, message}>(this.settings.restBaseUrl + 'contact/' + this.currentSelectedContactData.id, contactData, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
           if (count > 1) {
             const siteData = {
               contactId: this.currentSelectedContactData.id,
               siteId: this.siteIdToAssignContact
             };
             this.http.put<{status, message}>(this.settings.restBaseUrl + 'contact/company/address', siteData, this.settings.httpOptions)
                 .pipe(map(data2 => data2)).subscribe(
                 data2 => {
                   this.showLeft1Column = false;
                   this.showAssignToCompany = false;
                   this.view(this.currentSelectedContactData);
                   this.assignContactBack();
                 }
             );
           }
           if (this.currentSelectedCompanyId === 0) {
            this.http.get<{id, fk_site_id}>(this.settings.restBaseUrl + 'contact/company/address/' + this.currentSelectedContactData.id, this.httpOptions)
                .pipe(map(data3 => data3)).subscribe(
                data3 => {
                  const siteLink = data3;
                  if (siteLink != null) {
                    // @ts-ignore
                    this.http.delete<any>(this.settings.restBaseUrl + 'contact/company/sites/user/' + siteLink.id, this.httpOptions)
                        .pipe(map(data4 => data4)).subscribe(
                        data4 => {
                        }
                    );
                  }
                }
            );
            this.view(this.currentSelectedContactData);
            this.showLeft1Column = false;
            this.showAssignContact = false;
            this.siteIdToAssignContact = null;
            this.currentSelectedCompanyId = null;
          } else {
              this.view(this.currentSelectedContactData);
              this.showLeft1Column = false;
              this.showAssignContact = false;
              this.siteIdToAssignContact = null;
              this.currentSelectedCompanyId = null;
            }

            if(this.companyFromContact) {
              this.contactManagerMessageContent = 'Kontakt wurde in eine Firma gewandelt';
              this.companyFromContact=false;
            } else {
              this.contactManagerMessageContent = 'Kontakt wurde der Firma zugewiesen';
            }
            this.message.open('contactManagerMessageBox' + this.overlayId);
          }, 100);
        }
    );
  }

  selectCompanyToAssign(item) {
      this.currentSelectedCompanyId = item.company_id;
      this.siteIdToAssignContact = item.site_id;
  }

  dblClickselectContactToMerge(item) {
    this.selectContactToMerge(item);
    setTimeout(() => {
      this.mergeContactQuestion();
    }, 300);
  }

  dblClickSelectCompanyToAssign(item) {
    this.selectCompanyToAssign(item);
    setTimeout(() => {
      this.assignContactToCompany();
    }, 300);
  }

  unassignContact() {
    this.siteIdToAssignContact = 0;
    this.currentSelectedCompanyId = 0;
  }

  dblClickUnassignContact() {
    this.unassignContact();
    setTimeout(() => {
      this.assignContactToCompany();
    }, 300);
  }

  assignContactBack() {
    this.showLeft1Column = false;
    this.showAssignToCompany = false;
    this.siteIdToAssignContact = null;
    this.assignableSites = [];
    this.currentSelectedCompanyId = this.currentSelectedCompanyIdBackup;
    this.viewCompany(this.currentSelectedCompanyId);
    this.view(this.currentSelectedContactData);
  }

  mergeContactBack() {
    this.showLeft1Column = false;
    this.showMergeContact = false;
    this.assignableSites = [];
    this.mergeContactId = '';
  }

  checkAddContactToCompany(id){
    this.saveSwitchNumber = 3;
    this.idSaver = id;
    if (this.existingContact.length > 0) {
      this.contactManagerMessageContent = 'Ein Kontakt mit dieser E-Mail-Adresse wurde bereits angelegt.<br>Weisen Sie den Vorhandenen Kontakt neu zu wenn es sich um die gewünschte<br> Person handelt oder überprüfen Sie die eingegebenen Daten erneut.';
      this.message.open('contactManagerQuestionBox' + this.overlayId);
      return;
    } else{this.addContactToCompany(id);}
  }

  addContactToCompany(id) {
    this.closeUpdateInterfaces();

    if (this.contactContentData.salutation < 1) {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte wählen Sie eine Anrede aus.');
      return;
    } else if (this.contactContentData.name === '' || this.contactContentData.name == null) {
      alert('Alle Pflichtfelder müssen ausgefüllt sein.\nBitte geben Sie einen Namen ein.');
      return;
    }

    const contactData = {
      salutation: this.contactContentData.salutation,
      title: this.contactContentData.title,
      first_name: this.contactContentData.first_name,
      name: this.contactContentData.name,
      company: id,
      position: this.contactContentData.position,
      profession: this.contactContentData.profession
    };
    this.http.put<{status, message}>(
        this.settings.restBaseUrl + 'contact', contactData, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
            const newID = data.message;
            if (this.currentSelectedCompanyData.address.length > 0) {
              const siteLinkData = {
                fk_contact_id: newID,
                fk_site_id: this.siteIdToAssignContact
              };
              this.http.put<{status, message}>(
                  this.settings.restBaseUrl + 'contact/company/sites/user', siteLinkData, this.settings.httpOptions)
                  .pipe(map(data2 => data2)).subscribe(
                  data2 => {
                    if (data2.status === 'success') {
                      this.setContactConnections(newID);
                      setTimeout(() => {
                        this.siteIdToAssignContact = null;
                        const item = {id: newID};
                        this.view(item);
                      }, 300);
                    }
                  }
              );
            } else {
              this.setContactConnections(newID);
              setTimeout(() => {
                this.siteIdToAssignContact = null;
                const item = {id: newID};
                this.view(item);
              }, 300);
            }
        }
    );
  }

  addSiteToCompany() {
    this.closeUpdateInterfaces();
    const siteSaveData = {
      type: 11,
      site_name: this.addSiteData.site_name,
      site_short_name: this.addSiteData.site_short_name,
      street: this.addSiteData.street,
      street_number: this.addSiteData.street_number,
      postcode: this.addSiteData.postcode,
      city: this.addSiteData.city,
      fk_company_id: this.currentSelectedCompanyId,
      fk_contact_id: 0
    };
    this.http.put<{status, message}>(
        this.settings.restBaseUrl + 'contact/company/sites', siteSaveData, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.showCompanyNewSiteForm = false;
          this.addSiteData = {site_name: '', site_short_name: '', street: '', street_number: '', postcode: '', city: ''};
          this.viewCompany(this.currentSelectedCompanyId);
          setTimeout(() => {
            this.changeCompanyTab(data.message);
          }, 100);
        }
    );
  }

  setContactConnections(id) {
    if (this.contactContentData.email !== '' || this.contactContentData.phoneBusiness !== '' || this.contactContentData.faxBusiness !== '' || this.contactContentData.phonepublic !== '' || this.contactContentData.phoneMobile !== '') {

      let connectionData = {}
      const regi = new RegExp(/^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/);

      if(this.contactContentData.email !== ''){
      if(!regi.test(this.contactContentData.email))
      {
        this.message.open('wrongMail' + this.overlayId);

        connectionData = {
          e_mail: '',
          phone_business: this.contactContentData.phoneBusiness,
          fax_business: this.contactContentData.faxBusiness,
          phone_private: this.contactContentData.phonepublic,
          mobile_private: this.contactContentData.phoneMobile,
          fk_contact: id
        };
      } else {   connectionData = {
        e_mail: this.contactContentData.email,
        phone_business: this.contactContentData.phoneBusiness,
        fax_business: this.contactContentData.faxBusiness,
        phone_private: this.contactContentData.phonepublic,
        mobile_private: this.contactContentData.phoneMobile,
        fk_contact: id
      };}
      }else{   connectionData = {
        e_mail: this.contactContentData.email,
        phone_business: this.contactContentData.phoneBusiness,
        fax_business: this.contactContentData.faxBusiness,
        phone_private: this.contactContentData.phonepublic,
        mobile_private: this.contactContentData.phoneMobile,
        fk_contact: id
      };}

      this.http.put<{status, message, dataMessage}>(
          this.settings.restBaseUrl + 'contact/connection/0/1', connectionData, this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {

            if(data.message == 2){
            this.contactManagerMessageContent = data.dataMessage;
            this.message.open('domainUsed' + this.overlayId);}
            this.showLeft1Column = false;
            this.showNewItemContactForm = false;
            this.showCompanyAddContact = false;
            this.resetContactManager();
          }
      );
    } else {
      this.showLeft1Column = false;
      this.showNewItemContactForm = false;
      this.showCompanyAddContact = false;
      this.resetContactManager();
    }
  }

  addBookAccounts(company) {
    this.http.put<{status, message}>(
        this.settings.restBaseUrl + 'erp/account/link', { fk_book_account: this.bookAccountArray[0], fk_company: company }, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.bookAccountArray.shift();
          if (this.bookAccountArray.length > 0) {
              this.addBookAccounts(company);
          } else {
            if (this.contactContentData.name != null && this.contactContentData.name !== '' && this.contactContentData.salutation !== '-1') {
              this.addContactToCompany(company);
            } else {
              this.showLeft1Column = false;
              this.showNewItemCompanyForm = false;
              this.initCompanyContentData();
            }
          }
        }
    );
  }

  addContactConnection() {
    let mainConnection = 1;
    this.currentSelectedContactData.connection.forEach((element) => {
      if (this.selectedInfoType.id === '6' || this.selectedInfoType.id === '13' || this.selectedInfoType.id === '14' || this.selectedInfoType.id === '18' ) {
        if (element.type1 === '6' && element.type2 === '1' || element.type1 === '13' && element.type2 === '1' || element.type1 === '14' && element.type2 === '1' || element.type1 === '18' && element.type2 === '1') {
          mainConnection = 0;
        }
      }
      if (this.selectedInfoType.id === '8' || this.selectedInfoType.id === '20' ) {
        if (element.type1 === '8' && element.type2 === '1' || element.type1 === '20' && element.type2 === '1') {
          mainConnection = 0;
        }
      }
      });

    this.closeUpdateInterfaces();
    const connectionSaveData = {
      type1: this.selectedInfoType.id,
      type2: mainConnection,
      target: this.selectedInfoType.value,
      comment: ' ',
      fk_contact: this.currentSelectedContactData.id,
      fk_site_id: 0,
      public_team: this.selectedInfoType.public_team
    };

    const regi = new RegExp(/^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/);

    if(this.selectedInfoType.id === '8' && regi.test(this.selectedInfoType.value) || this.selectedInfoType.id !== '8')
    {
      this.http.put<{status, message, dataMessage}>(
          this.settings.restBaseUrl + 'contact/connection/0/0', connectionSaveData, this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            if ( this.currentSelectedContact.email === null && ( this.selectedInfoType.id === '1' || this.selectedInfoType.id === '20' ) ) {
              // Update current data wenn neue E-Mail
              this.currentSelectedContact.email = this.selectedInfoType.value;
              this.currentSelectedContactData.email = this.selectedInfoType.value;
            }
            if ( this.currentSelectedContact.phone === null && (  this.selectedInfoType.id === '1' || this.selectedInfoType.id === '6' || this.selectedInfoType.id === '13' ) ) {
              // Update current data wenn neue Telefonnummer
              this.currentSelectedContact.phone = this.selectedInfoType.value;
              this.currentSelectedContactData.phone = this.selectedInfoType.value;
            }

            if(data.message == 2){
              this.contactManagerMessageContent = data.dataMessage;
              this.message.open('domainUsed' + this.overlayId);}

            this.showContactInfoInputToAdd = false;
            this.selectedInfoType.id = '-1';
            this.selectedInfoType.value = '';
            this.selectedInfoType.public_team = '0';
            this.view(this.currentSelectedContactData);
          }
      );
    } else if(!regi.test(this.selectedInfoType.value)){this.message.open('wrongMail' + this.overlayId);}
  }

  addAddressToContact() {
    this.closeUpdateInterfaces();
    if (this.addContactAddressData.street == null || this.addContactAddressData.street === '') {
      alert('Alle Pflichtfelfer müssen ausgefüllt sein. Bitte geben Sie einen Straßennamen an.');
      return;
    } else if (this.addContactAddressData.street_number == null || this.addContactAddressData.street_number === '') {
      alert('Alle Pflichtfelfer müssen ausgefüllt sein. Bitte geben Sie eine Hausnummer an.');
      return;
    } else if (this.addContactAddressData.postcode == null || this.addContactAddressData.postcode === '') {
      alert('Alle Pflichtfelfer müssen ausgefüllt sein. Bitte geben Sie eine Postleitzahl an.');
      return;
    } else if (this.addContactAddressData.city == null || this.addContactAddressData.city === '') {
      alert('Alle Pflichtfelfer müssen ausgefüllt sein. Bitte geben Sie einen Straßennamen an.');
      return;
    }
    const addressSaveData = {
      site_name: ' ',
      site_short_name: ' ',
      type: 15,
      postcode: this.addContactAddressData.postcode,
      city: this.addContactAddressData.city,
      street: this.addContactAddressData.street,
      street_number: this.addContactAddressData.street_number,
      fk_contact_id: this.currentSelectedContactData.id,
      fk_company_id: 0,
      public_team: this.addContactAddressData.public_team
    };
    this.http.put<{status, message}>(
        this.settings.restBaseUrl + 'contact/company/sites', addressSaveData, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.showContactInfoInputToAdd = false;
          this.addContactAddressData = {street: '', street_number: '', postcode: '', city: '', public_team: '0'};
          this.view(this.currentSelectedContactData);
        }
    );
  }

  editContactData() {
    let userId = '';
    this.http.get<{status, message, data}>(this.settings.restBaseUrl + 'user/id', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              userId = data.data.userId;
            }
        ));

    const createUser = this.currentSelectedContactData.create_user;
    let company = '';
    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/company', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              company = data.message;
            }
        ));

    const actCompany =  this.currentSelectedCompanyId;
    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Kontaktmanager/Kontaktverwaltung', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              if (data.message === '1' || (userId === createUser && actCompany !== company)) {
                this.showEditContactData = true;
                this.showContactInfoInputToAdd = false;
              } else { this.message.open('favorite' + this.overlayId); }
            }
        ));
  }

  editContactInformation(item) {
    let userId = '';
    this.http.get<{status, message, data}>(this.settings.restBaseUrl + 'user/id', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              userId = data.data.userId;
            }
        ));

    const createUser = this.currentSelectedContactData.create_user;
    let company = '';
    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/company', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              company = data.message;
            }
        ));

    const actCompany =  this.currentSelectedCompanyId;
    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Kontaktmanager/Kontaktverwaltung', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              if (data.message === '1' || (userId === createUser && actCompany !== company)) {

                this.closeUpdateInterfaces();
                this.showEditContactInformation = true;
                this.currentlyEditingContactInfo = item.id;
                setTimeout(() => {
                  document.getElementById('editInfoValue').focus();
                }, 100);

              } else { this.message.open('favorite' + this.overlayId); }
            }
        ));
  }

  editContactAddress(item) {
    let userId = '';

    this.http.get<{status, message, data}>(this.settings.restBaseUrl + 'user/id', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              userId = data.data.userId;
            }
        ));

    const createUser = this.currentSelectedContactData.create_user;
    let company = '';

    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/company', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              company = data.message;
            }
        ));

    const actCompany =  this.currentSelectedCompanyId;

    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Kontaktmanager/Kontaktverwaltung', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              if (data.message === '1' || (userId === createUser && actCompany !== company)) {
                this.closeUpdateInterfaces();
                this.showAddressEdit = true;
                this.currentlyEditingContactAddress = item.id;
              } else { this.message.open('favorite' + this.overlayId); }
            }
        ));
  }

  updateContactData() {
    if( this.currentSelectedContactData.name.replaceAll(' ', '') === '') {
      this.contactManagerMessageContent = 'Das Feld Name darf nicht leer sein!';
      this.message.open('contactManagerMessageBox' + this.overlayId);
      return;
    }
    if (this.currentSelectedContactData.title === '-1') { this.currentSelectedContactData.title = 0; }
    const contactData = {
      salutation: this.currentSelectedContactData.salutation,
      title: this.currentSelectedContactData.title,
      name: this.currentSelectedContactData.name,
      first_name: this.currentSelectedContactData.first_name,
      company: this.currentSelectedContactData.company,
      position: this.currentSelectedContactData.position,
      profession: this.currentSelectedContactData.profession,
      search_number: this.currentSelectedContactData.search_number,
      site_name: this.currentSelectedContactData.site_name
    };
    this.http.put<{status, message}>(
        this.settings.restBaseUrl + 'contact/' + this.currentSelectedContact.id, contactData, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.http.get<{id, fk_site_id}>(this.settings.restBaseUrl + 'contact/company/address/' + this.currentSelectedContact.id , this.settings.httpOptions)
              .pipe(map(data2 => data2)).subscribe(
              data2 => {
                this.siteLink = data2;
                if (this.siteLink != null) {
                  this.http.put<{status, message}>(this.settings.restBaseUrl + 'contact/company/address/' + this.siteLink.id, { siteId: this.currentSelectedContactData.site }, this.settings.httpOptions)
                      .pipe(map(data3 => data3)).subscribe(
                      data3 => {
                        this.showEditContactData = false;
                        this.view(this.currentSelectedContactData);
                        this.viewCompany(this.currentSelectedCompanyId);
                      }
                  );
                } else {
                  this.showEditContactData = false;
                  this.view(this.currentSelectedContactData);
                  this.viewCompany(this.currentSelectedCompanyId);
                }
              }
          );
        }
    );
  }

  updateContactDataBack() {
    this.view(this.currentSelectedContactData);
    this.showEditContactData = false;
  }

  updateContactInformation(item) {
    const contactInfoData = {
        type1: item.type1,
        type2: item.type2,
        target: item.target,
        private_team: item.private_team,
        comment: ''
    };

    // Was macht das??
    if ( item.public_team > 0 || item.public_team === -1) { item.public = '1'; } else { item.public = '0'; }

    this.http.put<{status, message}>(
        this.settings.restBaseUrl + 'contact/connection/' + item.id, contactInfoData, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.showEditContactInformation = false;
          this.currentlyEditingContactInfo = null;
          this.view(this.currentSelectedContactData);
          this.viewCompany(this.currentSelectedContactData.company);
        }
    );
  }

  updateContactAddress(item) {
    if (item.street == null || item.street === '') {
      alert('Alle Pflichtfelfer müssen ausgefüllt sein. Bitte geben Sie einen Straßennamen an.');
      return;
    } else if (item.street_number == null || item.street_number === '') {
      alert('Alle Pflichtfelfer müssen ausgefüllt sein. Bitte geben Sie eine Hausnummer an.');
      return;
    } else if (item.postcode == null || item.postcode === '') {
      alert('Alle Pflichtfelfer müssen ausgefüllt sein. Bitte geben Sie eine Postleitzahl an.');
      return;
    } else if (item.city == null || item.city === '') {
      alert('Alle Pflichtfelfer müssen ausgefüllt sein. Bitte geben Sie einen Straßennamen an.');
      return;
    }
    const addressData = {
      site_name: ' ',
      site_short_name: ' ',
      type: 15,
      postcode: item.postcode,
      city: item.city,
      street: item.street,
      street_number: item.street_number,
      fk_contact_id: this.currentSelectedContactData.id,
      fk_company_id: 0,
      public_team: item.public_team
    };
    this.http.put<{status, message}>(
        this.settings.restBaseUrl + 'contact/company/sites/' + item.id, addressData, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.showAddressEdit = false;
          this.currentlyEditingContactAddress = null;
          this.view(this.currentSelectedContactData);
        }
    );
  }

  view(item) {
    this.showLeft1Column = false;
    this.showDeleteSiteAction = false;
    this.showAddressEdit = false;
    this.showContactAddressInputToAdd = false;
    this.showContactInfoInputToAdd = false;
    this.showNewItemDropdown = false;
    this.showCompanyAddContact = false;
    this.showCompanyNewSiteForm = false;
    this.showCompanyAddContact = false;
    this.showCompanyNewSiteForm = false;
    this.showExistingContactDropdown = false;
    this.closeUpdateInterfaces();
    this.currentSelectedContact = item;

    this.http.get<any>(this.settings.restBaseUrl + 'contact/' + item.id + '/full', this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          // @ts-ignore
          this.currentSelectedContactData = data;
            // tslint:disable-next-line:triple-equals
          if (this.currentSelectedContactData.company > 0) {
            this.changeToSiteAfterLoad = true;
            this.viewCompany(this.currentSelectedContactData.company);
          } else {
            this.initCompanyContentData();
            this.initCurrentSelectedCompanyData();
            this.currentSelectedCompanyId=0;
            this.buildDisplayDivContent();
          }
        }
    );
  }

  viewCompany(id) {
    this.site=0;
    this.closeUpdateInterfaces();
    this.showExistingContactDropdown = false;
    this.showContactAddressInputToAdd = false;
    this.showContactInfoInputToAdd = false;
    this.showNewItemDropdown = false;
    if (this.currentSelectedCompanyId === id) { this.checkContactSite(); return; }

    this.currentSelectedCompanyTab = 0;
    this.searchResultContact = null;
    if (id > 0) {
      this.http.get<any>(this.settings.restBaseUrl + 'contact/company/' + id , this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.currentSelectedCompanyId = id;
            this.currentSelectedCompanyData = data;
            this.setMainAddressIndex();
            this.showContactsRelatedToCompanyOrSite();
            this.buildDisplayDivContent();
            if(this.changeToSiteAfterLoad == true) {
              this.changeToSiteAfterLoad = false;
              if( this.currentSelectedCompanyData.address.length > 1 && this.currentSelectedContactData.site != this.currentSelectedCompanyTab && this.OverlayActive == true ) {
                this.changeCompanyTab(this.currentSelectedContactData.site);
                setTimeout(() => {
                  // Zum Kontakt in der Liste Scrollen
                  const element = document.getElementById('contact_' + this.currentSelectedContactData.id);
                  element.scrollIntoView({ behavior: "smooth" });
                }, 300);
              }
            }
          }
      );
    } else {
      this.currentSelectedCompanyId = 0;
      this.initCurrentSelectedCompanyData();
    }
  }

  checkContactSite() {
    if(this.currentSelectedCompanyData.address.length === 1) {
      // Wenn es keine Standorte gibt dann auch nicht wechseln
      return;
    }
    if ( this.currentSelectedContactData.site > 0 ) {
      this.changeCompanyTab(this.currentSelectedContactData.site);
    }
    this.showContactsRelatedToCompanyOrSite();
    this.buildDisplayDivContent();
  }

  changeCompanyTab(tab) {
    this.editCompany = false;
    this.editSite = false;
    this.showLeft1Column = false;
    this.currentSelectedCompanyTab = tab;
    this.showContactsRelatedToCompanyOrSite();
    if ( this.currentSelectedCompanyTab > 0) {
      for (const o of this.currentSelectedCompanyData.address) {
        if (o.id === this.currentSelectedCompanyTab) {
          this.currentSelectedSiteData = o;
        }
      }
    }
  }

  showContactsRelatedToCompanyOrSite() {
    if(this.OverlayActive == false) {
      return;
    }

    if (this.mode === 'free' && this.OverlayActive != true) { this.internMode = 'full'; } else if(this.OverlayActive == true) {this.internMode = 'contact'} else {this.internMode = this.mode}

    if( this.currentSelectedCompanyId < 1) {
      return;
    }
    this.http.post<{status,count,data}>(this.settings.restBaseUrl + 'contact', {company: this.currentSelectedCompanyId, site: this.currentSelectedCompanyTab, mode: this.internMode, active: 1 }, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          // @ts-ignore
          this.searchResultContact = data.data;

          this.showEmptyFavoriteFieldContact = false;
          this.searchResultContact.forEach((element) => {
            if (element.favorite != null) { this.showEmptyFavoriteFieldContact = true; }
          });
        }
    );
  }

  scrollToSuggestElement(x) {
    document.getElementById(this.overlayId).scrollTop = ( document.getElementById('suggestelement' + x.id).offsetTop - 100 );
  }

  blurSuggestSearch(event) {
    if( this.showQuickSave) {
      return;
    }
    setTimeout(() => {
      this.suggestSearch(event);
      if(this.suggestMode==2) { return;}
      this.suggestSearchActive = false;
    }, 100);
    setTimeout(() => {
      if(this.suggestMode==2) { return;}
      document.getElementById(this.overlayId).style.visibility = 'hidden';
      this.suggestSearchResult = [];
      this.SuggestOverlayActive = false;
      setTimeout(() => {
        this.suggestSearchString='';
      }, 100);
    }, 400);
  }


  suggestSearchKeyUp(event) {
    // Wenn Suche, dann Fenster wieder auf dynamische höhe stellen.
    document.getElementById(this.overlayId).style.height = 'unset';
    if (event.key === 'Escape') {
      this.deactivateSuggestOverlay();
      this.suggestKeyCurrentSelectedIndex = undefined;
      this.suggestKeyCurrentSelected = undefined;
      this.initQuicksaveData();
      return;
    } else if (event.key === 'Enter') {
      if(this.suggestSearchString === '') {
        this.displayTooltipContent = '';
        this.displayDivContent = '';
        this.suggestSearchActive = false;
        this.contactChange.emit('0');
        this.siteChange.emit('0');
        this.companyChange.emit('0');
        this.resetContactManager();
        return;
      }
      document.getElementById(this.overlayId).style.height = '250px';
      if ( this.suggestSearchResult.length < 1 ) {
        return; }

      if ( this.suggestKeyCurrentSelectedIndex === undefined ) {
        this.suggestKeyCurrentSelectedIndex = 0;
      }
      this.selectSuggestEntry(this.suggestSearchResult[this.suggestKeyCurrentSelectedIndex]);
      this.suggestKeyCurrentSelectedIndex = undefined;
      this.suggestKeyCurrentSelected = undefined;
      return;
    } else if (event.key === 'ArrowDown') {
      if (this.suggestKeyCurrentSelectedIndex === this.suggestSearchResult.length - 1) { return; }
      if (this.suggestKeyCurrentSelected === undefined) {
        this.suggestKeyCurrentSelectedIndex = 0;
      } else {
        this.suggestKeyCurrentSelectedIndex++;
      }
      this.suggestKeyCurrentSelected = this.suggestSearchResult[this.suggestKeyCurrentSelectedIndex];
      this.scrollToSuggestElement(this.suggestKeyCurrentSelected);
      return;
    } else if (event.key === 'ArrowUp') {
      if (this.suggestKeyCurrentSelectedIndex === 0) { return; }
      if (this.suggestKeyCurrentSelected === undefined) {
        this.suggestKeyCurrentSelectedIndex = 0;
      } else {
        this.suggestKeyCurrentSelectedIndex--;
      }
      this.suggestKeyCurrentSelected = this.suggestSearchResult[this.suggestKeyCurrentSelectedIndex];
      this.scrollToSuggestElement(this.suggestKeyCurrentSelected);
      return;
    }
      // Jede Eingabe löscht und setzt einen Timer neu für die Suche.
      clearTimeout(this.suggestSearchTimer);
      if  (this.suggestSearchString.length > 1) {
        this.suggestSearchTimer = setTimeout(() => {
          clearTimeout(this.suggestSearchTimer);
          this.suggestSearchTimer=null;
          this.suggestSearch(null)
        }, 800);
      }
  }

  suggestSearch(event) {
    const activeValue = 1;
    this.resetContactManager();
    if  (this.suggestSearchString.length > 1) {
      let group = false;
      if( this.suggestSearchString.length === 2) {group=true;}
      const reg = new RegExp('^[0-9]+$');
      if ( reg.test(this.suggestSearchString) ) {
        this.suggestViewAdditionalInformation = 'phone';
      } else if (this.suggestSearchString.search('@') !== -1) {
        this.suggestViewAdditionalInformation = 'email';
        this.quickSaveData.email = this.suggestSearchString;
      } else {this.suggestViewAdditionalInformation = ''; }

      const countValue = this.suggestSearchString.trim();
      const valueArray = countValue.split(/\s+/);
      if (valueArray.length > 4) { return; }

      if (this.mode === 'free') { this.internMode = 'full'; }else if(this.OverlayActive == true){this.internMode = 'contact'}else{this.internMode = this.mode}
      this.http.post<any>(this.settings.restBaseUrl + 'contact', {text: this.suggestSearchString, active: activeValue, mode: this.internMode, suggest: this.suggestMode , group: group}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            // Suchergebniss nicht verarbeiten, wenn die letzten 5 Sekunden ein Ergebniss in der Schnellsuche ausgewählt wurde.
            if(this.justClickedTimestamp > 0 && ( Math.floor(Date.now() / 1000) - this.justClickedTimestamp) < 5 ) { return; } else { this.justClickedTimestamp=0; }
            // @ts-ignore
            this.suggestSearchResult = data.data;
            if ( this.suggestSearchResult && this.suggestSearchResult.length > 0 ) { this.activateSuggestOverlay(); }
            if ( this.suggestSearchResult.length === 0 && this.suggestSearchString.length > 2 ) { this.activateSuggestOverlay(); }
            if (this.suggestSearchResult.length < 1 && this.suggestSearchString.length > 2 && this.suggestMode==2) {
              if(this.showQuickSave==false) {
                this.showQuickSave = true;
                this.switchQuickInsertMode('public');
              }
            } else { this.showQuickSave = false; }
          }
      );
    } else {
      this.suggestSearchResult = [];
      this.SuggestOverlayActive = false;
      setTimeout(() => {
        document.getElementById(this.overlayId).style.visibility = 'hidden';
      }, 100);
    }
  }

  public resetContactManager() {
    this.currentSelectedContact = null;
    this.initCurrentSelectedContactData();
    this.closeAssignUi();
    this.currentSelectedCompanyId = 0;
    this.initCurrentSelectedCompanyData();
    this.currentSelectedSite = null;
    this.currentSelectedSiteData = null;
    this.currentSelectedCompanyTab = 0;
    this.showContactAddressInputToAdd = false;
    this.showContactInfoInputToAdd = false;
    this.showNewItemDropdown = false;
    this.showCompanyAddContact = false;
    this.showCompanyNewSiteForm = false;
    this.showLeft1Column = false;
    this.showNewItemCompanyForm = false;
    this.showCompanyFavoriteContacts = false;
  }

  public hardResetContactManager() {
    this.contactManagerSearch.text = '';
    this.searchResultContact = null;
    this.searchResultCompany = null;
    this.currentSelectedContact = null;
    this.initCurrentSelectedContactData();
    this.closeAssignUi();
    this.currentSelectedCompanyId = 0;
    this.initCurrentSelectedCompanyData();
    this.currentSelectedSite = null;
    this.currentSelectedSiteData = null;
    this.currentSelectedCompanyTab = 0;
    this.showContactAddressInputToAdd = false;
    this.showContactInfoInputToAdd = false;
    this.showNewItemDropdown = false;
    this.showCompanyAddContact = false;
    this.showCompanyNewSiteForm = false;
    this.showLeft1Column = false;
    this.showNewItemCompanyForm = false;
  }

  activateSuggestSearch() {
    this.justClickedTimestamp = 0;
    this.suggestMode=1;
    this.deactivateTooltip();
    this.suggestSearchActive = true;
    setTimeout(() => {
      let e = document.getElementById('suggestSearchBox' + this.overlayId);
      if(e != null) {
        e.focus();
      }
    }, 200);
  }

  activateSuggestOverlay(): void {
    this.suggestKeyCurrentSelectedIndex = undefined;
    this.suggestKeyCurrentSelected = undefined;
    this.deactivateTooltip();
    this.SuggestOverlayActive = true;
    const e = document.getElementById(this.overlayId);
    if ( e !== null) {
      e.style.visibility = 'visible';
    }
  }


  deactivateSuggestOverlay(): void {
    this.suggestSearchString = '';
    this.suggestSearchActive = false;
    this.SuggestOverlayActive = false;
    setTimeout(() => {
      const e = document.getElementById(this.overlayId);
      if ( e !== null ) {
        e.style.visibility = 'hidden';
      }
    }, 100);
  }

  selectSuggestEntry(x: any) {
    this.justClickedTimestamp = Math.floor(Date.now() / 1000);
    this.suggestSelectedSoDontBuildDivLater = true;
    if (this.mode === 'email') {
      this.connectId = x.mailId;
      this.groupId = x.groupId;
    }
    if (this.mode === 'phone') {
      this.connectId = x.phoneId;
    }

    this.initNameStringsIntern();
    this.showContactAddressInputToAdd = false;
    this.showContactInfoInputToAdd = false;
    this.showNewItemDropdown = false;
    this.showCompanyAddContact = false;
    this.showCompanyNewSiteForm = false;

    this.displayDivContent = '';
    this.displayTooltipContent = '';

    if (this.mode === 'company') {
      if ( x.companyId > 0) {
        this.company = x.companyId;
        this.site = 0;
        this.contact = 0;

        this.displayDivContent = '<b>' + x.company + '</b> \n';
        this.displayTooltipContent = '<span><b>' + x.company + '</b></span>';
      } else {
        alert('Sie müssen einen Firma auswählen!'); return;
      }
    } else if (this.mode === 'site') {
      if ( x.companyId > 0 && x.siteId > 0) {
        this.company = x.companyId;
        this.site = x.siteId;
        this.contact = 0;

        this.displayDivContent = x.companyShortName + ' - <b>' + x.site_short_name + '</b>';
        this.displayTooltipContent = '<span><b style=\"margin-bottom:4px;\">' + x.site_name + '</b> </span>' + x.company + '<br>';
      } else {
        alert('Sie müssen einen Firma auswählen!'); return;
      }
    } else {
      if (this.mode === 'phone' && x.phone === null) {
        alert('Sie müssen einen Kontakt mit einer Telefonnummer auswählen!');
        return;
      }
      if (this.mode === 'email' && x.email === null) {
        alert('Sie müssen einen Kontakt mit einer E-Mail Adresse auswählen!');
        return;
      }


      if (x.salutationName != null) {
        this.displayDivContent +=  x.salutationName + ' ';
        this.nameStringsIntern.contact_salutation = x.salutationName;
      }

      if (x.titelName != null) {
        this.displayDivContent += x.titelName + ' ';
        this.nameStringsIntern.contact_title = x.titelName;
      }

      if (x.first_name != null) {
        this.displayDivContent += x.first_name + ' ';
        this.nameStringsIntern.contact_first_name = x.first_name;
      }

      this.displayDivContent +=  '<b>' + x.name + '</b> \n';
      this.nameStringsIntern.contact_name = x.name;

      this.displayTooltipContent = '<span>' + this.displayDivContent + '</span>';
      if (x.company !== null && x.company != undefined) {
        this.nameStringsIntern.company = x.company;
      } else {
        this.nameStringsIntern.company = '(Privatperson)';
      }

      if (x.companyShortName !== null && x.companyShortName != undefined ) {
        this.nameStringsIntern.company_short = x.companyShortName;
        this.displayTooltipContent += '<b style=\"margin-bottom:4px;\">' + x.company + '</b> \n';
        this.displayDivContent +=  '( ' + x.companyShortName + ' )';
      } else {
        this.nameStringsIntern.company_short = '(Privatperson)';
        this.displayDivContent +=  '( Privatperson )';
        this.displayTooltipContent += 'Privatperson\n';
      }

      if (x.site_name !== '') {
        this.nameStringsIntern.site = x.site_name;
      } else {
        this.nameStringsIntern.site = '(Privatperson)';
      }

      if (x.site_short_name !== '') {
        this.nameStringsIntern.site_short = x.site_short_name;
      } else {
        this.nameStringsIntern.site_short = '(Privatperson)';
      }

      if (x.phone != null) {
        this.displayTooltipContent += x.phone + ' \n';
        if (this.mode === 'phone') {
          this.displayDivContent += ' ' + x.phone;
        }
      }

      if (x.email != null) {
        this.displayTooltipContent += x.email + ' ';
        if (this.mode === 'email') {
          this.displayDivContent += ' ' + x.email;
        }
      }
    }

    if ( x.phone !== '' ) {
      this.nameStringsIntern.phone = x.phone;
    }

    if ( x.email !== '' ) {
      this.nameStringsIntern.email = x.email;
      this.nameStringsIntern.groupId = x.groupId;
    }

    this.nameStrings.emit(this.nameStringsIntern);

    if (this.mode === 'free') {
    if (x.type && x.type > 0 && !x.first_name) { // Site
      this.company = x.fk_company_id;
      this.site = x.id;
      this.contact = 0;
      this.displayDivContent = x.companyShortName + ' - <b>' + x.site_short_name + '</b>';
      this.displayTooltipContent = '<span><b style=\"margin-bottom:4px;\">' + x.site_name + '</b></span>\n';
      this.displayTooltipContent += '<i>(Standort)</i><br>';
      this.displayTooltipContent += x.company + '<br>';
    } else if (x.mark === "C") { // company
      this.company = x.id;
      this.site = 0;
      this.contact = 0;
      this.displayDivContent = '<b>' + x.company + '</b> (Firma)';
      this.displayTooltipContent = '<span><b>' + x.company + '</b></span>';
      this.displayTooltipContent += '<i>(Firma)</i>';
    } else { // Contact
      this.company = x.companyId;
      this.site = x.siteId;
      this.contact = x.id;
      this.displayTooltipContent = '<span>' + x.salutationName + ' ' + x.first_name + '<b> ' + x.name + '</b></span>\n';
      if (x.companyId > 0) { this.displayTooltipContent += '<b>' + x.company + '</b>\n'; } else { this.displayTooltipContent += '(Privatperson)\n'; }
      if (x.phone) { this.displayTooltipContent += x.phone + '\n'; }
      if (x.email) { this.displayTooltipContent += x.email + '\n'; }
    }
    } else {
      if (this.mode === 'company') {
        this.company = x.companyId;
        this.site = 0;
        this.contact = 0;
      } else if (this.mode === 'site') {
        this.company = x.companyId;
        this.site = x.siteId;
        this.contact = 0;
      } else {
        if(x.mark === "C") {
          this.company = x.id;
          this.site = 0;
          this.contact = 0;
          this.groupId = 0;
        } else {
          this.company = x.companyId;
          this.site = x.siteId;
          this.contact = x.id;
          this.groupId = x.groupId;
        }

      }
    }

    this.companyChange.emit(this.company);
    this.siteChange.emit(this.site);
    this.contactChange.emit(this.contact);
    this.connectIdChange.emit(this.connectId);
    this.groupIdChange.emit(this.groupId);
    const parentThis = this;
    setTimeout(() => { parentThis.onChange.emit(); }, 100);
    setTimeout( () => { this.deactivateSuggestOverlay(); } , 300);
    this.deactivateSuggestOverlay();
  }

  suggestSearchEnd() {
    this.toolTipContent = undefined;
    this.deactivateSuggestOverlay();
  }

  activateTooltip(content) {
    if ( content !== undefined && content !== '') {
      this.toolTipContent = content;
      setTimeout(() => {
        this.delayedActivateTooltip();
      }, 1000);
    }
  }

  delayedActivateTooltip() {
    if ( this.toolTipContent !== undefined) {
      document.getElementById('tooltip' + this.overlayId).style.width = 'fit-content';
      document.getElementById('tooltip' + this.overlayId).style.height = 'fit-content';
      document.getElementById('tooltip' + this.overlayId).style.visibility = 'visible';
    }
  }

  deactivateTooltip() {
    this.toolTipContent = undefined;
    const e = document.getElementById('tooltip' + this.overlayId);
    if ( e !== null) {
     e.style.visibility = 'hidden';
    }
  }

  activateView(viewId) {
    this.userActionMenu.show = false;
    if(viewId === 3){

      this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Kontaktmanager/Kontaktgruppenverwaltung', this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          (data => {
            if (data.message === '1') {   this.viewMode = viewId;
              this.search(); } else { this.message.open('rightCheckGroup' + this.overlayId); }   }
      ));
      } else if (viewId === 1) {
      this.viewMode = viewId;
        if(this.contactManagerSearch.text === '' ) {
          this.showFavorites();
        } else {
          this.search();
        }
      } else {
          this.viewMode = viewId;
          this.search();
      }
  }

  updateList() {
    this.listFilter.text = this.contactManagerSearch.text;
    this.listFilter.tag = this.contactManagerSearch.tag;
    this.listFilter.active = this.contactManagerSearch.active;
    this.listData = [];
    this.showLoading = true;
      this.http.post<any>(this.settings.restBaseUrl + 'contact', JSON.stringify(this.listFilter), this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.showLoading = false;
            // @ts-ignore
            this.listData = data.data;
          }
      );
  }

  updateGroupList() {
    this.http.post<any>(this.settings.restBaseUrl + 'contact/group', JSON.stringify(this.groupFilter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          // @ts-ignore
          this.groupData = data.data;
        }
    );
  }

  checkExistingContact() {
    if (this.showQuickSave) {
      if (this.quickSaveData.name.length > 2) {
        this.http.post<any>(this.settings.restBaseUrl + 'contact/existing', {first_name: this.quickSaveData.first_name, name: this.quickSaveData.name, email: this.quickSaveData.email}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
              // @ts-ignore
              this.existingContact = data.data;
              if (this.existingContact.length > 0) { this.showExistingContactDropdown = true; } else { this.showExistingContactDropdown = false; }
            }
        );
      } else {
        this.showExistingContactDropdown = false;
      }
    } else {
      if (this.contactContentData.name.length > 2) {
        this.http.post<any>(this.settings.restBaseUrl + 'contact/existing', {first_name: this.contactContentData.first_name, name: this.contactContentData.name, email: this.contactContentData.email}, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
              // @ts-ignore
              this.existingContact = data.data;
              if (this.existingContact.length > 0) { this.showExistingContactDropdown = true; } else { this.showExistingContactDropdown = false; }
            }
        );
      } else {
        this.showExistingContactDropdown = false;
      }
    }
  }

  openExistingContact($event: MouseEvent, item: any) {
    this.mode = 'email';
    this.modeValue = this.mode;
    this.selectSuggestEntry(item);

    this.closeAssignUi();

      this.deactivateSuggestOverlay();
      this.contactChange.emit(item.id);

    setTimeout(() => {
      this.view(item);
      if (this.showQuickSave) {
        setTimeout(() => {
          this.initQuicksaveData();
        }, 300);
        this.showQuickSave = false;
      }
    }, 100);

    setTimeout(() => {
    this.http.post<any>(this.settings.restBaseUrl + 'contact/reactivate', {id: item.id}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {

           setTimeout(() => {this.addContact.emit();
            }, 900);

        }
    );}, 300);
  }


  closeExistingContactOverlay() {
    setTimeout(() => {
      this.showExistingContactDropdown = false;
    }, 300);
  }

  closeUpdateInterfaces() {
    this.showAddressEdit = false;
    this.showEditContactInformation = false;
    this.showEditContactData = false;
  }

  public closeAssignUi() {
    this.showLeft1Column = false;
    this.showAssignContact = false;
    this.showNewItemContactForm = false;
    this.showCompanyAddContact = false;
  }

  setMainContact(item) {
    this.http.put<any>(this.settings.restBaseUrl + 'contact/connection/main/' + item.id + '/' + item.type1, { fk_contact: this.currentSelectedContactData.id }, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          // tslint:disable-next-line:triple-equals
          if (item.type1 == 8 || item.type1 == 20 || item.type1 == 29 ) {
            this.currentSelectedContactData.connection.forEach((element) => {
              // tslint:disable-next-line:triple-equals
              if (element.type1 == 8 || element.type1 == 20 || element.type1 == 29 && element.id !== item.id ) {
                element.type2 = 0;
              }
                  });
          } else {
            // tslint:disable-next-line:triple-equals
            if (item.type1 == 6 || item.type1 == 13 || item.type1 == 14 || item.type1 == 18) {
              this.currentSelectedContactData.connection.forEach((element) => {
                // tslint:disable-next-line:triple-equals
                if (element.type1 == 6 || element.type1 == 13 || element.type1 == 14 || element.type1 == 18 && element.id !== item.id) {
                  element.type2 = 0;
                }
              });
            }
          }
          item.type2 = 1;
        });
  }

  editCompanyData(currentSelectedCompanyData: any) {
    if(currentSelectedCompanyData === -1) {
      this.editTab = 0;
    } else {
      this.editTab = 1;
    }
    let userId = '';

    this.http.get<{status, message, data}>(this.settings.restBaseUrl + 'user/id', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              userId = data.data.userId;
            }
        ));

    //const createUser = currentSelectedCompanyData.address[0].create_user;

    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Kontaktmanager/Kontaktverwaltung', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
            (data => {
              if (data.message === '1' /*|| userId === createUser*/) {
                this.editCompanyDomains = 1;
                if ( currentSelectedCompanyData === -1) {
                  this.editCompany = false;
                } else {
                  if (this.currentSelectedCompanyData.address.length === 0) {
                    this.currentSelectedCompanyData.address.push({ street: '', street_number: '', postcode: '', city: '' });
                  }
                  this.editCompany = true;
                }

              } else { this.editCompany = false; this.message.open('favorite' + this.overlayId); }
            }
        ));

  }

  editSiteData(currentSelectedSiteData: any) {

    let userId = '';

    this.http.get<{status, message, data}>(this.settings.restBaseUrl + 'user/id', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              userId = data.data.userId;
            }
        ));

    const createUser = this.currentSelectedCompanyData.address[0].create_user;

    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Kontaktmanager/Kontaktverwaltung', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              if (data.message === '1' || userId === createUser) {


                if ( currentSelectedSiteData === -1) {
                  this.editSite = false;
                } else {
                  this.editSite = true;
                }

              } else { this.message.open('favorite' + this.overlayId); }
            }
        ));
  }

  updateSiteData() {
    this.http.put<any>(this.settings.restBaseUrl + 'contact/company/sites', JSON.stringify(this.currentSelectedSiteData), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.editSite = false;
        }
    );
    /* todo delete site data
    this.http.put<any>(this.settings.restBaseUrl + 'contact/company', JSON.stringify(this.currentSelectedCompanyData), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
        }
    );*/
  }

  updateCompanyData(closeEditForm) {


    const regi = new RegExp(/^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/);

      if(this.currentSelectedCompanyData.bill_receiver == null) { this.currentSelectedCompanyData.bill_receiver = '' }
      if(!regi.test(this.currentSelectedCompanyData.bill_receiver) && (this.currentSelectedCompanyData.bill_receiver != ''))
      {this.message.open('wrongMail' + this.overlayId);}


    else if((this.currentSelectedCompanyData.bill_receiver == '') || regi.test(this.currentSelectedCompanyData.bill_receiver)){
    this.editTab = 0;
    const address = this.currentSelectedCompanyData.address[0];
    if (address.street === '' && address.street_number === '' && address.postcode === '' && address.city === ''  ) {
      this.currentSelectedCompanyData.address = [];
    }
    this.searchResultContact.forEach((item) => {
     if ( item.id === this.currentSelectedCompanyData.point_of_contact ) {
       this.currentSelectedCompanyData.point_of_contact_string = '';
       if ( item.name) { this.currentSelectedCompanyData.point_of_contact_string += item.name + ', '; }
       if ( item.first_name) { this.currentSelectedCompanyData.point_of_contact_string += item.first_name + ' '; }

     }
    });
    let siteId = 0;
    this.currentSelectedCompanyData.address.forEach((element) => {
      // tslint:disable-next-line:triple-equals
      if (element.type == 10) { siteId = element.id; }
    });

    this.http.put<any>(this.settings.restBaseUrl + 'contact/company', JSON.stringify(this.currentSelectedCompanyData), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          // only save if one address
          if(this.currentSelectedCompanyData.address.length == 1) {
            if(this.currentSelectedCompanyData.address[0].street !=='' ||
                this.currentSelectedCompanyData.address[0].street_number !=='' ||
                this.currentSelectedCompanyData.address[0].postcode !=='' ||
                this.currentSelectedCompanyData.address[0].city !=='') {
              const siteSaveData = {
                type: 10,
                site_name: this.currentSelectedCompanyData.name,
                site_short_name: this.currentSelectedCompanyData.nickname,
                street: this.currentSelectedCompanyData.address[0].street,
                street_number: this.currentSelectedCompanyData.address[0].street_number,
                postcode: this.currentSelectedCompanyData.address[0].postcode,
                city: this.currentSelectedCompanyData.address[0].city,
                fk_company_id: this.currentSelectedCompanyId,
                fk_contact_id: 0
              };
              this.http.put<{status, message}>(
                  this.settings.restBaseUrl + 'contact/company/sites/' + siteId, siteSaveData, this.settings.httpOptions)
                  .pipe(map(data2 => data2)).subscribe(
                  data2 => {
                    if (closeEditForm) { this.editCompany = false; }
                    this.setMainAddressIndex();
                    });
            } else { if (closeEditForm) { this.editCompany = false; } }
          } else {
            if (closeEditForm) { this.editCompany = false; }
          }
        }
    );}
  }

  resetSearch() {
    this.resetContactManager();
    this.contactManagerSearch = { text: '', active:'1', tag:0};
    this.initListFilter();
    this.initGroupFilter();
    this.searchResultCompany = [];
    this.searchResultContact = [];
    this.listData = [];
    setTimeout( function focus() { document.getElementById('contactManagerSearch').focus(); }, 200);
    this.getFavoriteCompaniesList();
    this.getFavoriteContactsList();
  }

  showFavorites() {
    this.resetSearch();
  }

  setStarDistributor(param: any) {
    if ( this.currentSelectedCompanyData.distributor === 1 && param === 1 ) { this.currentSelectedCompanyData.distributor = 0;
    } else { this.currentSelectedCompanyData.distributor = param; }
    this.updateCompanyData(false);
  }

  setStarCustomer(param: any) {
    if ( this.currentSelectedCompanyData.customer === 1 && param === 1 ) { this.currentSelectedCompanyData.customer = 0;
    } else { this.currentSelectedCompanyData.customer = param; }
    this.updateCompanyData(false);
  }

  deleteCompany() {
    let userId = '';

    this.http.get<{status, message, data}>(this.settings.restBaseUrl + 'user/id', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              userId = data.data.userId;
            }
        ));

    //const createUser = this.currentSelectedCompanyData.address[0].create_user;

    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Kontaktmanager/Kontaktverwaltung', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              if (data.message === '1' /*|| userId === createUser*/) {

                this.message.open('companyDeleteQuestion' + this.overlayId);


              } else { this.message.open('favorite' + this.overlayId); }
            }
        ));
  }

  deleteCompanyContacts() {
    if (this.searchResultContact && this.searchResultContact.length > 0) {
      this.http.put<any>(this.settings.restBaseUrl + 'contact/active/' + this.searchResultContact[0].id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {

            this.http.delete<any>(this.settings.restBaseUrl + 'contact/company/sites/user/' + this.searchResultContact[0].linkId , this.httpOptions)
                .pipe(map(data2 => data2)).subscribe(
                data2 => {
                  this.searchResultContact.shift();
                  if (this.searchResultContact.length > 0) {
                    this.deleteCompanyContacts();
                  } else {
                    this.deactivateSitesOfCompany();
                  }
                }
            );
          }
      );
    } else {
      this.deactivateSitesOfCompany();
    }

    this.showDeleteSiteAction = true;
    this.showLeft1Column = true;

  }

  deactivateSitesOfCompany() {
    this.http.put<any>(this.settings.restBaseUrl + 'contact/company/sites/active/' + this.currentSelectedCompanyData.address[0].id + '/0', {}, this.httpOptions)
        .pipe(map(data2 => data2)).subscribe(
        data2 => {
          this.currentSelectedCompanyData.address.shift();
          if (this.currentSelectedCompanyData.address.length > 0) {
            this.deactivateSitesOfCompany();
          } else {
            this.deactivateCompany();
          }
        }
    );
  }

  deactivateCompany() {
    this.http.put<any>(this.settings.restBaseUrl + 'contact/company/active/' + this.currentSelectedCompanyId + '/0' ,{}, this.httpOptions)
        .pipe(map(data2 => data2)).subscribe(
        data2 => {
          this.search();
        }
    );
  }

  deleteSite(value) {
    let userId = '';

    this.http.get<{status, message, data}>(this.settings.restBaseUrl + 'user/id', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              userId = data.data.userId;
            }
        ));

    const createUser = this.currentSelectedCompanyData.address[0].create_user;

    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Kontaktmanager/Kontaktverwaltung', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              if (data.message === '1' || userId === createUser) {

                if (value === 'open') {
                  this.currentSelectedCompanyData.address.forEach((element) => {
                    if (element.id !== this.currentSelectedSiteData.id) {
                      this.selectableNewMainAddresses.push(element);
                    }
                  });
                }
                if (this.newSelectedMainAddress !== '' && this.currentSelectedCompanyData.address.length > 2) {
                  this.mainAddressId = this.newSelectedMainAddress;
                } else if (this.selectableNewMainAddresses.length === 1) {
                  this.mainAddressId = this.selectableNewMainAddresses[0].id;
                  if (this.deleteSiteAction === 'reassign') {
                    this.siteIdToReassign = this.selectableNewMainAddresses[0].id;
                  }
                }
                if (this.deleteSiteAction === '' && this.newSelectedMainAddress === '') {
                  this.message.open('siteDeleteQuestion' + this.overlayId);
                }
                if (this.searchResultContact && this.searchResultContact.length > 0 && this.deleteSiteAction === 'delete') {
                  this.deactivateContact();
                  return;
                }
                if (this.searchResultContact && this.searchResultContact.length > 0 && this.deleteSiteAction === 'reassign') {
                  if (this.selectableNewMainAddresses.length === 1) {
                    this.siteIdToReassign = this.selectableNewMainAddresses[0].id;
                    this.deleteContactLink();
                    return;
                  }
                  if (this.selectableNewMainAddresses.length > 0 && this.siteIdToReassign === '' || this.siteIdToReassign == null) {
                    alert('Bitte wählen Sie einen Standort dem die Kontakte des zu löschenden Standorts zugewiesen werden sollen.');
                    return;
                  } else {
                    this.reassignSiteContacts();
                    return;
                  }
                }
                if (this.searchResultContact.length < 1) {
                  this.deleteSiteTabFromInterface();
                }


              } else { this.message.open('favorite' + this.overlayId); }
            }
        ));
  }

  deleteCompanySite()
  {
    if (this.searchResultContact && this.searchResultContact.length > 0 && this.deleteSiteAction === '') {
      this.showDeleteSiteAction = true;
      this.showLeft1Column = true;
      return;
    }
  }

  deactivateContact() {
    this.http.put<any>(this.settings.restBaseUrl + 'contact/active/' + this.searchResultContact[0].id + '/0', {},this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {

    this.http.delete<any>(this.settings.restBaseUrl + 'contact/company/sites/user/' + this.searchResultContact[0].linkId , this.httpOptions)
        .pipe(map(data2 => data2)).subscribe(
        data2 => {
          this.searchResultContact.shift();
          if (this.searchResultContact.length > 0) {
            this.deactivateContact();
          } else {
            this.deleteSiteTabFromInterface();
          }
        }
    );
        }
    );
  }

  deleteContactLink() {
    this.http.delete<any>(this.settings.restBaseUrl + 'contact/company/sites/user/' + this.searchResultContact[0].linkId , this.httpOptions)
        .pipe(map(data2 => data2)).subscribe(
        data2 => {
          this.searchResultContact.shift();
          if (this.searchResultContact.length > 0) {
            this.deleteContactLink();
          } else {
            this.deleteSiteTabFromInterface();
          }
        }
    );
  }

  reassignSiteContacts() {
    this.http.put<any>(this.settings.restBaseUrl + 'contact/company/address/' + this.searchResultContact[0].linkId, {siteId: this.siteIdToReassign}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.searchResultContact.shift();
          if (this.searchResultContact.length > 0) {
            this.reassignSiteContacts();
          } else {
            this.deleteSiteTabFromInterface();
          }
        }
    );
  }

  deleteSiteTabFromInterface() {

    if (this.currentSelectedCompanyData.main_fk_addresses === this.currentSelectedSiteData.id && this.newSelectedMainAddress === '' && this.currentSelectedCompanyData.address.length > 2 ) {
      this.showLeft1Column = true;
      this.showDeleteSiteAction = true;
      this.showMainAddressSelect = true;
      return;
    } else {
        const companyAddressData = {
            name: this.currentSelectedCompanyData.name,
            nickname: this.currentSelectedCompanyData.nickname,
            tel: this.currentSelectedCompanyData.tel,
            tel_main: this.currentSelectedCompanyData.tel_main,
            mail_domains: this.currentSelectedCompanyData.mail_domains,
            customer_id: this.currentSelectedCompanyData.customer_id,
            distributor_id: this.currentSelectedCompanyData.distributor_id,
            notice: this.currentSelectedCompanyData.notice,
            distributor: this.currentSelectedCompanyData.distributor,
            customer: this.currentSelectedCompanyData.customer,
            receivable_accounts: this.currentSelectedCompanyData.receivable_accounts,
            payable_accounts: this.currentSelectedCompanyData.payable_accounts,
            point_of_contract: this.currentSelectedCompanyData.point_of_contract,
            main_fk_addresses: this.mainAddressId,
            intern: this.currentSelectedCompanyData.intern,
            active: this.currentSelectedCompanyData.active
          };
        this.http.put<any>(this.settings.restBaseUrl + 'contact/company/' + this.currentSelectedCompanyId , companyAddressData, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
              this.currentSelectedCompanyData.main_fk_addresses = this.mainAddressId;
              this.setMainAddressIndex();

              this.http.put<any>(this.settings.restBaseUrl + 'contact/company/sites/active/' + this.currentSelectedSiteData.id + '/0', {}, this.httpOptions)
                  .pipe(map(data2 => data2)).subscribe(
                  data2 => {
                    for ( let i = 0; i < this.currentSelectedCompanyData.address.length; i += 1 ) {
                      if ( this.currentSelectedCompanyData.address[i].id === this.currentSelectedSiteData.id ) {
                        this.changeCompanyTab(0);
                        this.currentSelectedCompanyData.address.splice(i, 1);
                      }
                    }
                    this.deleteSiteBack();
                  }
              );
            }
        );
    }
  }

  deleteSiteBack() {
    this.showDeleteSiteAction = false;
    this.deleteSiteAction = '';
    this.siteIdToReassign = '';
    this.showLeft1Column = false;
    this.showDeleteSiteAction = false;
    this.selectableNewMainAddresses = [];
    this.newSelectedMainAddress = '';
  }

  deleteContactData() {
    if(this.currentSelectedContactData.login == '1') {
      // Wenn login dann nicht möglich
      this.contactManagerMessageContent = "Der Kontakt hat ein Anmeldekonto und kann deshalb nur in der Benutzerverwaltung deaktiviert werden!";
      this.message.open('contactManagerMessageBox' + this.overlayId);
      return;
    }
    let userId = '';

    this.http.get<{status, message, data}>(this.settings.restBaseUrl + 'user/id', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              userId = data.data.userId;
            }
        ));

    let company = '';

    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/company', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              company = data.message;
            }
        ));

    const actCompany =  this.currentSelectedCompanyId;

    const createUser = this.currentSelectedContactData.create_user;

    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Kontaktmanager/Kontaktverwaltung', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              if (data.message === '1' || (userId === createUser && actCompany !== company)) {
                // Dialog zur Bestätigung aufrufen > springt dann zu deleteContactFinish
                this.message.open('deleteDialog' + this.overlayId);
              } else {
                this.message.open('favorite' + this.overlayId);
              }
            }
        ));
  }

  deleteContactFinish() {
    this.message.close('deleteDialog' + this.overlayId);
    // Wird im Dialog deleteDialog aufgerufen
    this.http.put<any>(this.settings.restBaseUrl + 'contact/active/' + this.currentSelectedContact.id + '/0', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (this.searchResultContact) {
            for ( let i = 0; i < this.searchResultContact.length; i += 1 ) {
              if ( this.searchResultContact[i].id === this.currentSelectedContact.id ) {
                this.searchResultContact.splice(i, 1);
              }
            }
          }
          this.currentSelectedContact = null;
          this.currentSelectedContactData = null;
        }
    );
  }

  activateContact() {
    this.http.put<any>(this.settings.restBaseUrl + 'contact/active/' + this.currentSelectedContact.id + '/1', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelectedContact = null;
          this.currentSelectedContactData = null;
        }
    );
  }

  deleteContactInformation(item: any) {
    let userId = '';

    this.http.get<{status, message, data}>(this.settings.restBaseUrl + 'user/id', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              userId = data.data.userId;
            }
        ));

    const createUser = this.currentSelectedContactData.create_user;

    let company = '';

    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/company', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              company = data.message;
            }
        ));

    const actCompany =  this.currentSelectedCompanyId;

    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Kontaktmanager/Kontaktverwaltung', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              if (data.message === '1' || (userId === createUser && actCompany !== company)) {


                const dialog = window.confirm('Wollen Sie die Information wirklich unwiederruflich Löschen?');
                if (!dialog) { return; }

                this.http.delete<any>(this.settings.restBaseUrl + 'contact/connection/' + item.id, this.settings.httpOptions)
                    .pipe(map(data => data)).subscribe(
                    data => {
                      const index = this.currentSelectedContactData.connection.indexOf(item);
                      this.currentSelectedContactData.connection.splice(index, 1);
                    }
                );


              } else { this.message.open('favorite' + this.overlayId); }
            }
        ));
  }

  deleteContactAddress(item: any) {
    let userId = '';

    this.http.get<{status, message, data}>(this.settings.restBaseUrl + 'user/id', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              userId = data.data.userId;
            }
        ));

    const createUser = this.currentSelectedContactData.create_user;
    let company = '';
    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/company', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              company = data.message;
            }
        ));

    const actCompany =  this.currentSelectedCompanyId;

    this.http.get<{status, message}>(this.settings.restBaseUrl + 'user/right/Kontaktmanager/Kontaktverwaltung', this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        (data => {
              if (data.message === '1' || (userId === createUser && actCompany !== company)) {

                const dialog = window.confirm('Wollen Sie die Information wirklich unwiederruflich Löschen?');
                if (!dialog) { return; }

                this.http.delete<any>(this.settings.restBaseUrl + 'contact/company/sites/' + item.id, this.settings.httpOptions)
                    .pipe(map(data => data)).subscribe(
                    data => {
                      const index = this.currentSelectedContactData.address.indexOf(item);
                      this.currentSelectedContactData.address.splice(index, 1);
                    }
                );

              } else { this.message.open('favorite' + this.overlayId); }
            }
        ));
  }

  public setMainAddressIndex() {
    if (this.currentSelectedCompanyData.main_fk_addresses > 0) {
      for (let i = 0; i < this.currentSelectedCompanyData.address.length; i += 1) {
        if (this.currentSelectedCompanyData.address[i].id === this.currentSelectedCompanyData.main_fk_addresses) {
          this.currentSelectedCompanyData.mainAdressIndex = i;
          break;
        }
      }
      this.checkContactSite();
    } else { this.currentSelectedCompanyData.mainAdressIndex = 0; }
  }

  quickSave() {
    let saveWithContact = this.saveSwitchMode;
    // Wenn speichern dann sugest Company Liste leeren
    this.suggestedCompany = [];
    this.showCompanyOverlay = false;

    const regi = new RegExp(/^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/);
    if(this.quickSaveData.email == null) { this.quickSaveData.email = '' }
    if(((this.quickSaveData.email !== '' && regi.test(this.quickSaveData.email) || this.quickSaveData.email === '') && saveWithContact == 1) || saveWithContact == 0)
    {

    let quicksaveCompanyId;
    let quicksaveSiteId;

    if(saveWithContact == 1){
    if (this.quickSaveData.salutation < 1) {
      this.contactManagerMessageContent = 'Wählen Sie eine Anrede aus.';
      this.message.open('contactManagerMessageBox' + this.overlayId);
    }
    if (this.quickSaveData.name === '' || this.quickSaveData.name == null) {
      this.contactManagerMessageContent = 'Geben Sie einen Namen ein.' + this.saveSwitchMode;
      this.message.open('contactManagerMessageBox' + this.overlayId);
      return;
    }
    if (this.quickSaveData.phone === '' && this.quickSaveData.email === '' && this.mode == 'email') {
      this.contactManagerMessageContent = 'Geben Sie eine E-Mail ein.';
      this.message.open('contactManagerMessageBox' + this.overlayId);
      return;
    }
      if (this.quickSaveData.phone === '' &&  this.mode == 'phone') {
        this.contactManagerMessageContent = 'Geben Sie eine Telefonnummer ein.';
        this.message.open('contactManagerMessageBox' + this.overlayId);
        return;
      }}
    if (this.showCompanyData && this.quickSaveData.companyId < 1) {
      if (this.quickSaveData.company_name === '' || this.quickSaveData.company_name == null) {
        alert('Die Pflichtfelder müssen ausgefüllt sein. Geben Sie einen Firmennamen an.'); return;
      } else if (this.quickSaveData.company_nickname === '' || this.quickSaveData.company_nickname == null) {
        alert('Die Pflichtfelder müssen ausgefüllt sein. Geben Sie einen Spitznamen für die Firma an.'); return;
      }
    }


    if (this.quickSaveData.company_name !== '' && this.quickSaveData.company_nickname !== '') {
      this.http.put<any>(this.settings.restBaseUrl + 'contact/company', {
        name: this.quickSaveData.company_name,
        nickname: this.quickSaveData.company_nickname,
        active: 1
      }, this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            quicksaveCompanyId = data.message;
            if (this.quickSaveData.site_name === '' && this.quickSaveData.site_short_name === '') {
              this.quickSaveData.site_name = this.quickSaveData.company_name;
              this.quickSaveData.site_short_name = this.quickSaveData.company_nickname;
            }
            const siteData = {
              type: 10,
              site_name: this.quickSaveData.site_name,
              site_short_name: this.quickSaveData.site_short_name,
              postcode: this.quickSaveData.postcode,
              city: this.quickSaveData.city,
              street_number: this.quickSaveData.street_number,
              street: this.quickSaveData.street,
              fk_contact_id: 0,
              fk_company_id: data.message
            };
            this.http.put<any>(this.settings.restBaseUrl + 'contact/company/sites', siteData, this.settings.httpOptions)
                .pipe(map(data2 => data2)).subscribe(
                data2 => {
                  quicksaveSiteId = data2.message;
                }
            );
            if(saveWithContact == 1){
            setTimeout(() => {
              this.quickSaveContact(quicksaveCompanyId, quicksaveSiteId);
            }, 500);} else{

              this.http.get<any>(this.settings.restBaseUrl + 'contact/' + data.message + '/full', this.settings.httpOptions)
                  .pipe(map(data3 => data3)).subscribe(
                  data3 => {
                    this.currentSelectedContactData = data3;

                    this.http.get<any>(this.settings.restBaseUrl + 'contact/company/' + data.message , this.httpOptions)
                        .pipe(map(data5 => data5)).subscribe(
                        data5 => {
                          this.currentSelectedCompanyId = quicksaveCompanyId;
                          this.currentSelectedCompanyData = data5;

                          if (quicksaveSiteId > 0 && this.currentSelectedContactData.id > 0) {
                            this.http.put<any>(this.settings.restBaseUrl + 'contact/company/sites/user', {fk_contact_id: this.currentSelectedContactData.id, fk_site_id: quicksaveSiteId}, this.settings.httpOptions)
                                .pipe(map(data6 => data6)).subscribe(
                                data6 => {
                                }
                            );
                          }
                          const selectObject = {
                            'active' : '1',
                            companyId: quicksaveCompanyId,
                            'id' : quicksaveCompanyId,
                            company: this.quickSaveData.company_name,
                            companyShortName: this.quickSaveData.company_nickname,
                            mark:'C',
                            'name' : this.quickSaveData.company_name,
                            'nickname' : this.quickSaveData.company_nickname
                                 };

                          this.deactivateOverlay();
                          document.getElementById(this.overlayId).style.height = '250px';
                          setTimeout(() => {
                            this.selectSuggestEntry(selectObject);
                          }, 500);
                          setTimeout(() => {
                            this.initQuicksaveData();
                            this.showQuickSave = false;
                            this.showCompanyData = false;
                            this.showQuickSaveCompanySelected = false;
                          }, 300);
                        }
                    );
                  }
              );


            }
          }
      );
    } else {
      this.quickSaveContact(this.quickSaveData.companyId, this.quickSaveData.siteId);
    } } else if(!regi.test(this.selectedInfoType.value)){this.message.open('wrongMail' + this.overlayId);}
  }
    quickSaveContact(companyID, siteID) {
    const contactData = {
        salutation: this.quickSaveData.salutation,
        title: this.quickSaveData.title,
        first_name: this.quickSaveData.first_name,
        name: this.quickSaveData.name,
        company: companyID,
        position: '',
        profession: '',
        birth_date: 0
    };
    this.http.put<any>(this.settings.restBaseUrl + 'contact', contactData, this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            if (siteID > 0) { this.assignContactToSite(data.message, siteID); }
            let phoneValue;
            let emailValue;
            if (this.quickSaveData.phone !== '') { phoneValue = this.quickSaveData.phone; } else { phoneValue = ''; }
            if (this.quickSaveData.email !== '') { emailValue = this.quickSaveData.email; } else { emailValue = ''; }
            const contactConnectionData = { phone_business: phoneValue, e_mail: emailValue, fk_contact: data.message };

            this.http.put<any>(this.settings.restBaseUrl + 'contact/connection/0/1', contactConnectionData, this.settings.httpOptions)
                .pipe(map(data2 => data2)).subscribe(
                data2 => {
                    const contactUpdateData = {
                      salutation: this.quickSaveData.salutation,
                      title: this.quickSaveData.title,
                      name: this.quickSaveData.name,
                      first_name: this.quickSaveData.first_name,
                      company: companyID,
                      position: this.quickSaveData.position,
                      profession: this.quickSaveData.profession,
                      search_number: this.quickSaveData.search_number
                    };

                    this.http.put<any>(this.settings.restBaseUrl + 'contact/' + data.message, contactUpdateData, this.settings.httpOptions)
                        .pipe(map(data4 => data4)).subscribe(
                        data4 => {
                          this.http.get<any>(this.settings.restBaseUrl + 'contact/' + data.message + '/full', this.settings.httpOptions)
                              .pipe(map(data3 => data3)).subscribe(
                              data3 => {
                                this.currentSelectedContactData = data3;
                              }
                          );
                        }
                    );
                    this.http.get<any>(this.settings.restBaseUrl + 'contact/' + data.message + '/full', this.settings.httpOptions)
                        .pipe(map(data3 => data3)).subscribe(
                        data3 => {
                          this.currentSelectedContactData = data3;

                          this.http.get<any>(this.settings.restBaseUrl + 'contact/company/' + data.message , this.httpOptions)
                            .pipe(map(data5 => data5)).subscribe(
                            data5 => {
                              this.currentSelectedCompanyId = companyID;
                              this.currentSelectedCompanyData = data5;

                              if (siteID > 0) {
                                this.http.put<any>(this.settings.restBaseUrl + 'contact/company/sites/user', {fk_contact_id: this.currentSelectedContactData.id, fk_site_id: siteID}, this.settings.httpOptions)
                                    .pipe(map(data6 => data6)).subscribe(
                                    data6 => {
                                    }
                                );
                              }
                              const selectObject = {
                                id: this.currentSelectedContactData.id,
                                companyId: this.currentSelectedContactData.company,
                                company: this.currentSelectedCompanyData.name,
                                companyShortName: this.currentSelectedCompanyData.nickname,
                                siteId: this.currentSelectedContactData.site,
                                site_name: this.currentSelectedContactData.site_name,
                                site_short_name: this.currentSelectedContactData.site_short_name,
                                phone: this.currentSelectedContactData.phone,
                                email: this.currentSelectedContactData.email,
                                salutationName: this.currentSelectedContactData.salutationString,
                                titelName: this.currentSelectedContactData.titleString,
                                name: this.currentSelectedContactData.name,
                                first_name: this.currentSelectedContactData.first_name
                              };

                              this.deactivateOverlay();
                              document.getElementById(this.overlayId).style.height = '250px';
                              setTimeout(() => {
                                this.selectSuggestEntry(selectObject);
                              }, 300);
                              setTimeout(() => {
                                this.initQuicksaveData();
                                this.showQuickSave = false;
                                this.showCompanyData = false;
                                this.showQuickSaveCompanySelected = false;
                              }, 500);
                            }
                        );
                        }
                    );
                }
            );
          }
      );
  }

  suggestCompaney($event: KeyboardEvent) {
    if (this.companyContentData.name.length > 2) {
      this.http.post<any>(this.settings.restBaseUrl + 'contact/company', {text: this.companyContentData.name}, this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.suggestedCompany = data;
            if (this.suggestedCompany.length > 0 && this.companyContentData.name.length > 2) {
              this.showCompanyOverlay = true;
            } else {
              this.showCompanyOverlay = false;
            }
          }
      );
    } }

  suggestCompany($event: KeyboardEvent) {
    if ($event.key === 'Escape') {
      this.quickSaveData.company_name = '';
      this.quickSaveData.companyId = 0;
      this.deactivateCompanyOverlay();
    }
    if (this.quickSaveData.company_name.length > 2) {
      this.http.post<any>(this.settings.restBaseUrl + 'contact/company', {text: this.quickSaveData.company_name}, this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.suggestedCompany = data;
            if (this.suggestedCompany.length > 0 && this.quickSaveData.company_name.length > 2) {
              this.showCompanyOverlay = true;
            } else {
              this.deactivateCompanyOverlay();
              this.quickSaveData.companyId = 0;
              this.quickSaveData.siteId = 0;
            }
          }
      );
    } else {
      this.showQuickSaveCompanySelected = false;
      setTimeout(() => {
        // document.getElementById(this.overlayId).style.height = '465px';
        document.getElementById('companyNameUnselected').focus();
      }, 100);
    }
  }

  quickSaveSelectCompany(item) {
    if(this.quickSaveData.type == 'companyOnly') {
      const selectObject = {
        'active' : '1',
        'company' : item.name,
        'id' : item.id,
        'mark' : 'C',
        'name' : item.name,
        'nickname' : item.name
      };
      this.selectSuggestEntry(selectObject);
      // im nur Firma Modus die Firma auswählen
      this.deactivateCompanyOverlay();
      this.initQuicksaveData();
      return;
    }
    document.getElementById(this.overlayId).style.height = '380px';
    this.quickSaveData.company_name = item.name;
    this.quickSaveData.companyId = item.id;
    this.showQuickSaveCompanySelected = true;
    this.quickSaveStrings.emit(this.quickSaveData.company_name);
    this.http.post<any>(this.settings.restBaseUrl + 'contact/company/address', { company: item.id }, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.quicksaveSiteData = data;
          if (this.quicksaveSiteData.length > 0) {
            this.quickSaveData.siteId = this.quicksaveSiteData[0].id;
          } else { this.quickSaveData.siteId = 0; }
        }
    );
    this.deactivateCompanyOverlay();
  }

  backToSelectCompany() {
    this.quickSaveData.companyId = 0;
    this.quickSaveData.company_name = '';
    this.quickSaveData.company_nickname = '';
    this.quickSaveData.siteId = 0;
    this.quickSaveData.site_short_name = '';
    this.quickSaveData.site_name = '';
    this.showQuickSaveCompanySelected = false;
    setTimeout(() => {
      this.switchQuickInsertMode('company');
    }, 100);
    this.showCompanyOverlay = false;
  }

  checkExistingDomain($event: KeyboardEvent) {
    if ($event.key === 'Escape') {
      this.quickSaveData.email = '';
      this.switchQuickInsertMode('public');
    }
    const domainString = this.quickSaveData.email.replace(/.*@/, '');
    if (domainString.length < 3) {
      this.showSuggestedDomain = false;
    } else {
      this.http.post<any>(this.settings.restBaseUrl + 'contact/company/domain', { text: domainString }, this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.suggestedDomain = data;
            if (this.suggestedDomain.length > 0) { this.showSuggestedDomain = true; } else { this.showSuggestedDomain = false; }
          }
      );
    }
  }

  quickSaveSelectDomain(item) {
    this.quickSaveData.companyId = item.id;
    const address = this.quickSaveData.email.match(/[^@]*/i)[0];
    this.quickSaveData.email = address + item.mail_domain;
    this.showCompanyData = true;
    this.showSuggestedDomain = false;
    this.quickSaveData.type = 'company';
    const company = { id: item.id, name: item.name };
    this.quickSaveSelectCompany(company);
  }

  assignContactToSite(contactID, siteID) {
    this.http.put<any>(this.settings.restBaseUrl + 'contact/company/address', { contactId: contactID, siteId: siteID }, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
        }
    );
  }

  checkExistingName($event: KeyboardEvent) {
    if ($event.key === 'Escape') {
      this.quickSaveData.name = '';
      this.showSuggestedName = false;
    }
    if (this.quickSaveData.name.length < 2) {
      this.showSuggestedName = false;
    } else {
      this.http.post<any>(this.settings.restBaseUrl + 'contact', { text: this.quickSaveData.name, active: -1 }, this.settings.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            this.suggestedName = data.data;
            if (this.suggestedName.length > 0) { this.showSuggestedName = true; } else { this.showSuggestedName = false; }
          }
      );
    }
  }

  quickSaveSelectName(item) {
    const selectObject = {
      id: item.id,
      companyId: item.companyId,
      company: item.company,
      companyShortName: item.companyShortName,
      siteId: item.siteId,
      site_name: item.site_name,
      site_short_name: item.site_short_name,
      phone: item.phone,
      email: item.email,
      salutationName: item.salutationName,
      titelName: item.titelName,
      name: item.name,
      first_name: item.first_name,
      active: item.active
    };
    this.showSuggestedName = false;
    this.switchQuickInsertMode('public');
    this.closeQuickSaveOverlay();
    this.selectSuggestEntry(selectObject);
  }

  deactivateCompanyOverlay() {
    this.showCompanyOverlay = false;
  }

  switchQuickInsertMode(mode) {
    this.quickSaveData.type = mode;
    if(mode == 'public') {
      this.saveSwitchMode = 1;
      this.showContactData = true;
      this.showCompanyData = false;
      setTimeout(() => { document.getElementById(this.overlayId).style.height = '300px'; }, 100);
    } else if(mode == 'company') {
      this.saveSwitchMode = 1;
      this.showContactData = true;
      this.showCompanyData = true;
      setTimeout(() => { document.getElementById(this.overlayId).style.height = '465px'; }, 100);
    } else if(mode == 'companyOnly') {
      this.saveSwitchMode = 0;
      this.showCompanyData = true;
      this.showContactData = false;
      setTimeout(() => { document.getElementById(this.overlayId).style.height = '300px'; }, 100);
    }
  }

  closeQuickSaveOverlay() {
    this.initQuicksaveData();
    this.deactivateSuggestOverlay();
    this.showCompanyData = false;
    this.showCompanyOverlay = false;
    this.showSuggestedName = false;
    this.showSuggestedDomain = false;
  }

  setFavorite(contactId, companyId) {
    this.http.put<{ status, message }>(this.settings.restBaseUrl + 'contact/favorite', { fk_contact: contactId, fk_company: companyId }, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (contactId < 1) {
            // @ts-ignore
            this.currentSelectedCompanyData.favorite = data.message;
          } else if (companyId < 1) {
            // @ts-ignore
            this.currentSelectedContactData.favorite = data.message;
          }
        }
    );
  }

  deleteFavouriteDialog(x: any)
  {
    this.message.open('deleteFavouriteQuestion' + this.overlayId);
    this.favouriteToDelete = x;
  }

  deleteFavourite()
  {
    this.unsetFavorite(this.favouriteToDelete);
    this.message.close('deleteFavouriteQuestion' + this.overlayId);
    this.contactManagerMessageContent = 'Favorit erfolgreich entfernt';
    this.message.open('contactManagerMessageBox' + this.overlayId);
  }

  unsetFavorite(x: any) {
    this.http.delete<any>(this.settings.restBaseUrl + 'contact/favorite/' + x.favorite, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (x.company) {
            this.currentSelectedContactData.favorite = null;
          } else {
            this.currentSelectedCompanyData.favorite = null;
          }
        }
    );
  }

  getFavoriteCompaniesList() {
    this.http.post<any>(this.settings.restBaseUrl + 'contact/favorite/company', {  }, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.searchResultCompany = data;
          this.favoritesLoaded = true;
        }
    );
  }

  getFavoriteContactsList() {
    this.http.post<any>(this.settings.restBaseUrl + 'contact/favorite/contact', {  }, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.searchResultContact = data;
        }
    );
  }

  unsetFavoriteSearchResultContact(x: any) {
    this.http.delete<any>(this.settings.restBaseUrl + 'contact/favorite/' + x.favorite, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          x.favorite = null;
          let favoritesRemain = false;
          this.searchResultContact.forEach((element) => {
            if (element.favorite != null) { favoritesRemain = true; }
          });
          setTimeout(() => {
            if (this.favoritesLoaded) {
              this.getFavoriteContactsList();
            } else { this.search(); }
            this.showEmptyFavoriteFieldContact = favoritesRemain;
            }, 300);
        });
  }

  unsetFavoriteSearchResultCompany(x: any) {
    this.http.delete<any>(this.settings.restBaseUrl + 'contact/favorite/' + x.favorite, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          x.favorite = null;
          let favoritesRemain = false;
          this.searchResultCompany.forEach((element) => {
            if (element.favorite != null) { favoritesRemain = true; }
          });
          setTimeout(() => {
            if (this.favoritesLoaded) {
              this.getFavoriteCompaniesList();
            } else { this.search(); }
            this.showEmptyFavoriteFieldCompany = favoritesRemain;
          }, 300);
        });
  }

  selectCurrentConnectionTarget(x: any) {
    // tslint:disable-next-line:triple-equals
    if (this.mode === 'email' && x.type1 == 8 || this.mode === 'email' && x.type1 == 20 || this.mode === 'email' && x.type1 == 29 ) {
      this.currentSelectedContactData.email = x.target;
      this.currentSelectedContactData.mailId = x.id;
      this.selectedMailId = x.id;
      this.selectSuggestEntry(this.currentSelectedContactData);
      // tslint:disable-next-line:triple-equals
    } else if (this.mode === 'phone' && x.type1 == 6 || this.mode === 'phone' && x.type1 == 13 || this.mode === 'phone' && x.type1 == 14 || this.mode === 'phone' && x.type1 == 18 ) {
      this.currentSelectedContactData.phone = x.target;
      this.currentSelectedContactData.phoneId = x.id;
      this.selectedPhoneId = x.id;
      this.selectSuggestEntry(this.currentSelectedContactData);
    }
  }

  belatedCloseSuggestNameOverlay() {
    setTimeout(() => {
      this.closeQuickSaveSuggestNameOverlay();
    }, 500);
  }

  closeQuickSaveSuggestNameOverlay() {
    this.showSuggestedName = false;
  }

  saveGroup() {
    this.http.put<any>(this.settings.restBaseUrl + 'contact/group', JSON.stringify(this.currentSelectedContactGroup) , this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if ( this.currentSelectedContactGroup.id < 1 ) {
            this.currentSelectedContactGroup.id = data.message;
            this.currentSelectedContactGroup.items = [];
          }
          this.updateGroupList();
        }
    );
  }

  newContactGroup() {
    if ( this.viewMode !== 3 ) {
      this.viewMode = 3;
      this.groupFilter.text = this.contactManagerSearch.text;
      this.updateGroupList();
    }
    this.initCurrentSelectedGroupData();
  }

  editGroup(x) {
    this.http.get<any>(this.settings.restBaseUrl + 'contact/group/' + x.id, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelectedContactGroup = data.data;
          this.http.post<any>(this.settings.restBaseUrl + 'contact/group/item' , {id: data.data.id}, this.settings.httpOptions)
              // tslint:disable-next-line:no-shadowed-variable
              .pipe(map(data => data)).subscribe( data => {
                if ( data.data !== undefined && data.data.length > 0) {
                  this.currentSelectedContactGroup.items = data.data;
                } else {
                  this.currentSelectedContactGroup.items = [];
                }
              }
          );
        }
    );
  }

  listPrint() {
  }

  listExcelExport() {
  }

  changeGroupContactString(event: any) {
    this.newGroupContactNameString = event;
  }

  addGroupContact() {
    setTimeout(() => {
      this.delayAddGroupContact();
    }, 100);
  }

  delayAddGroupContact() {
    if ( this.newGroupContactItem.contact < 1 ) {
      this.addGroupContact();
      return;
    }
    // prüfen ob bereits enthalten
    for (const item of this.currentSelectedContactGroup.items) {
      if ( item.fk_contact === this.newGroupContactItem.contact && item.fk_connection === this.newGroupContactItem.connectId) {
        this.groupContactManagerReset = false;
        setTimeout(() => {
          this.initNewGroupContactItem();
          this.groupContactManagerReset = true;
        }, 50);
        this.message.open('groupItemExists' + this.overlayId);
        return;
      }
    }

    let newGroupContactName = '';
    if ( this.newGroupContactNameString.contact_first_name !== '' ) { newGroupContactName += this.newGroupContactNameString.contact_first_name + ' '; }
    if ( this.newGroupContactNameString.contact_name !== '' ) { newGroupContactName += this.newGroupContactNameString.contact_name + ' '; }
    if ( newGroupContactName === '' ) { newGroupContactName = this.newGroupContactNameString.email; }

    const newGroupItem = {
      id: 0,
      fk_contact: this.newGroupContactItem.contact,
      fk_connection: this.newGroupContactItem.connectId,
      email: this.newGroupContactNameString.email,
      name: newGroupContactName};

    this.http.put<any>(this.settings.restBaseUrl + 'contact/group/item', {fk_contact_group: this.currentSelectedContactGroup.id, fk_contact: this.newGroupContactItem.contact, fk_connection: this.newGroupContactItem.connectId} , this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          newGroupItem.id = data.message;
          this.currentSelectedContactGroup.items.push(newGroupItem);
          this.groupContactManagerReset = false;
          setTimeout(() => {
            this.initNewGroupContactItem();
            this.groupContactManagerReset = true;
          }, 50);
        }
    );
  }

  deleteGroupItem(x) {
    this.http.delete<any>(this.settings.restBaseUrl + 'contact/group/item/' + x.id , this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          const index = this.currentSelectedContactGroup.items.indexOf(x);
          this.currentSelectedContactGroup.items.splice(index, 1);
        }
    );
  }

  deleteGroupQuestion(x) {
    this.groupItemToDelete = x;
    this.currentSelectedContactGroup = null;
    this.message.open('groupDeleteQuestion' + this.overlayId);

  }

  deleteGroup() {
    this.message.close('groupDeleteQuestion' + this.overlayId);
    this.http.delete<any>(this.settings.restBaseUrl + 'contact/group/' + this.groupItemToDelete.id, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          const index = this.groupData.indexOf(this.groupItemToDelete);
          this.groupData.splice(index, 1);
          this.groupItemToDelete = null;
        }
    );
  }

  deleteGroupStop() {
    this.groupItemToDelete = null;
    this.message.close('groupDeleteQuestion' + this.overlayId);
  }

  saveListEdit() {
    // Speichern
    this.userActionMenu.show = false;
    this.http.put<{status, message, newPhoneId, newMailId }>(this.settings.restBaseUrl + 'contact/list' , JSON.stringify(this.currentListEditItem), this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if(data.newPhoneId > 0) {
            this.currentListEditItem.phone_id = data.newPhoneId;
          }
          if(data.newMailId > 0) {
            this.currentListEditItem.email_id = data.newMailId;
          }
          this.currentListEditItem.listEdit = 0;
        }
    );
  }

  startListEdit(x, field) {
    if(this.currentListEditItem!==null) {
      if(this.currentListEditItem.listEdit === 2) {
        // Wurde bearbeitet
        if(this.currentListEditItem.salutation === '0') {
          alert('Bitte wählen Sie eine Anrede!');
          return;
        }
        if(this.currentListEditItem.name === '') {
          alert('Sie müssen einen Namen angeben!');
          return;
        }
        this.saveListEdit();
      }
      this.currentListEditItem.listEdit = 0;
    }
    x.listEdit = 1;
    this.currentListEditItem = x;
    setTimeout(() => {
      let element = '';
      switch(field) {
        case 1: element = 'salutation_'; break;
        case 2: element = 'firstName_'; break;
        case 3: element = 'name_'; break;
        case 4: element = 'position_'; break;
        case 5: element = 'email_'; break;
        case 6: element = 'phone_'; break;
      }
      if(element!=='') {
        document.getElementById( element + this.currentListEditItem.id).focus();
      }
    }, 300);
  }

  changedListEdit($event: KeyboardEvent) {
    if( $event.key === 'Escape' ) {
      // Bearbeiten abbrechen
      this.currentListEditItem.listEdit = 0;
      this.currentListEditItem = null;
    }
    this.currentListEditItem.listEdit = 2;
  }

  openContactActionMenu(event: MouseEvent) {
    if(this.userActionMenu.show) {
      this.userActionMenu.show = false;
    } else {
      this.userActionMenu = {show:true,x:event.x-126,y:event.y+4};
    }
  }

  executeUserAction(actionNumber: number) {
    switch ( actionNumber ) {
      case 0:
        // Suche Vorgänge zu Kontakt
        const contactToSearch = this.currentListEditItem.id;
        this.userActionMenu.show = false;
        this.currentListEditItem.listEdit = 0;
        this.currentListEditItem = null;
        this.deactivateOverlay();
        this.router.navigate(['/process/' + contactToSearch + '/search']);
        break;
      case 1:
        // Archivieren und aus Liste entfernen
        const idToDelete = this.currentListEditItem.id;
        let setTo;
        if(this.contactManagerSearch.active === '1' ) { setTo = '0'; } else { setTo =  '1';}
        this.http.put<{status, message}>(this.settings.restBaseUrl + 'contact/active/' + idToDelete + '/' + setTo ,{} , this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
              for(let i=0;i<this.listData.length;i++) {
                if(this.listData[i].id === idToDelete) {
                  this.listData.splice(i, 1);
                }
              }
            });
        break;
      case 2:
        // statement 2
        break;
      case 3:
        // statement N
        break;
    }
    this.userActionMenu.show = false;
    this.currentListEditItem.listEdit = 0;
    this.currentListEditItem = null;
  }

  changeCompanyEditTab(number: number) {
    this.editTab = number;
  }

  addDistributorBookAccount() {
    for(let i=0; i<this.currentSelectedCompanyData.book_accounts.length;i++) {
      if(this.currentSelectedCompanyData.book_accounts[i].fk_book_account==this.currentSelectedCompanyData.newAccount) {
        this.contactManagerMessageContent = 'Das Konto befindet sich bereits in der Liste!!';
        this.currentSelectedCompanyData.newAccount = -1;
        this.message.open('contactManagerMessageBox' + this.overlayId);
        return;
      }
    }
    for(let i=0; i<this.bookAccountsList.length;i++) {
      if(this.bookAccountsList[i].id==this.currentSelectedCompanyData.newAccount) {
        this.http.put<{status, message}>(this.settings.restBaseUrl + 'contact/company/distributorbookaccount' , { 'fk_company' : this.currentSelectedCompanyData.id , 'fk_book_account' : this.bookAccountsList[i].id} , this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
              this.currentSelectedCompanyData.book_accounts.push({ 'id': data.message, 'sort' : this.currentSelectedCompanyData.book_accounts.length+1 ,  'account_number': this.bookAccountsList[i].account_number , 'fk_book_account': this.bookAccountsList[i].id, 'title': this.bookAccountsList[i].title });
              this.currentSelectedCompanyData.newAccount = -1;
            });
        return;
      }
    }
  }

  deleteDistributorBookAccount(acc) {
    let elementDeletedChangeSort = false;
    for(let i=0; i<this.bookAccountsList.length;i++) {
      if(this.currentSelectedCompanyData.book_accounts[i].fk_book_account==acc.fk_book_account) {
        this.http.delete<{status, message}>(this.settings.restBaseUrl + 'contact/company/distributorbookaccount/' + this.currentSelectedCompanyData.book_accounts[i].id ,  this.settings.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
              this.currentSelectedCompanyData.newAccount = -1;
            });
        this.currentSelectedCompanyData.book_accounts.splice(i, 1);
        elementDeletedChangeSort = true;
      }
      if(elementDeletedChangeSort == true) {
        this.currentSelectedCompanyData.book_accounts[i].sort --;
      }
    }
  }

  userAction(action,x) {

    // Wenn kein Recht dann verlassen
    if(this.usermanagmentRight==false) {return};
    setTimeout(() => {
      switch ( action ) {
        case 1:
          // Konto aktivieren Dialog öffnen
          this.view(x);
          this.message.open('contactManagerActivateUser' + this.overlayId);
          break;
        case 2:
          // Konto bearbeiten
          this.deactivateOverlay();
          this.router.navigate(['/admin-base/user/edit/' + x.id]);
          this.resetContactManager();
          break;
        case 3:
          // Neues Konto erstellen
          this.deactivateOverlay();
          this.router.navigate(['/admin-base/user/new/' + x.id  + '/' + x.name]);
          this.resetContactManager();
          break;
      }
    }, 0);
  }

  activateUser() {
    this.http.put<{status, message}>(this.settings.restBaseUrl + 'user/active/'+this.currentSelectedContact.id+'/1' , { } , this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.currentSelectedContact.login='1';
          this.message.close('contactManagerActivateUser' + this.overlayId);
        });
  }

  suggestSearchAll() {
    this.suggestMode = 2;
  }

  activateQuickEdit() {
    this.suggestSearchResult = [];
    this.showQuickSave = true;
    this.suggestSearchActive = true;
    setTimeout(() => {
      this.showQuickSave = true;
      this.switchQuickInsertMode('public');
      this.suggestSearchActive = true;
    }, 500);
  }
}
